<template>
  <section class="hero-header">
    <div class="container">
      <div class="cta">
        <h1>There's more to sports betting than luck.<br/><span class="highlight-green">It's us.</span></h1>
      </div>

      <div class="header-animation">
        <div class="character-animation">
          <lottie-animation
              path="static/assets/home-animation.json"
              :loop="true"
              :autoPlay="true"
          />
        </div>
      </div>

      <div class="hero-bullets">
        <div class="bullet-header">
          <div class="gs-animate fade enter">
            A tech company that boosts the sports betting experience.
          </div>
        </div>

        <div class="bullet">
          <div class="gs-animate fade enter">
            <h2>Superology</h2>
            <p>creates entertainment solutions for the sports betting industry. While most platforms offer either
              betting
              or sports stats, we merged them, and built the experience up with live streams, community and more.</p>
          </div>
          <div class="superology-bullet-logo large-logo-padding">
            <img src="../../assets/superology-hero-bullet-logo.svg" alt="Superology">
          </div>
        </div>

        <div class="bullet">
          <p class="gs-animate fade enter">As a part of the Superbet group, our innovations are being delivered to
            millions of Superbet app users. We can tell they appreciate it because the active user spends 34 minutes
            daily using the app.</p>

          <div class="superbet-bullet-logo small-logo-padding">
            <img src="../../assets/superbet-bullet-logo.svg" alt="Superbet">
          </div>
        </div>

<!--        <div class="bullet gs-animate fade enter">-->
<!--          <a class="bullet-btn get-to-know-us" href="#">-->
<!--            <ApplyArrow hoverColor="black"/>-->
<!--            get to know us</a>-->
<!--        </div>-->
      </div>

      <div class="container visible-desktop">
        <div class="squiggly-wrap">
          <div class="squiggly-half">
            <div class="squiggly-line"></div>
            <div class="squiggly-right-curve"></div>
          </div>
        </div>
      </div>
    </div>

  </section>
</template>

<script>
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation'
import ApplyArrow from '@/components/careers/ApplyArrow.vue'

export default {
  name: 'HeroHeader',

  props: {},

  data: function () {
    return {}
  },

  mounted() {

  },

  components: {LottieAnimation, ApplyArrow},

  computed: {},

  methods: {}
}


</script>
