export default {
    main: '/careers',
    productDesigner: '/careers/product-designer',
    productDesignerDS: '/careers/product-designer-design-systems',
    uxResearcher: '/careers/ux-researcher',
    androidDeveloper: '/careers/android-developer',
    dataAnalyst: '/careers/data-analyst',
    backendDeveloper: '/careers/backend-developer',
    frontendWebDeveloper: '/careers/frontend-web-developer',
    openApplication: '/careers/open-application',
    productManager: '/careers/product-manager',
    qaEngineer: '/careers/qa-engineer',
    iosDeveloper: '/careers/ios-developer',
    devops: '/careers/devops',
    kotlinMultiplatformDeveloper: '/careers/kotlin-multiplatform-developer',
    javascriptDeveloper: '/careers/javascript-developer',
}
