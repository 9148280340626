<template>
  <section class="outdated-browser">

    <div class="header">
      <div class="container">
        <div class="broken-logo">
          <img class="logo" src="../assets/broken-logo.svg" alt="Suerology">
          <img class="slogan" src="../assets/broken-slogan.svg" alt="The scienece of being super">
        </div>
      </div>
    </div>

    <div class="content">
      <div class="container">

        <div class="angry-browser">
          <img src="../assets/angry-browser.svg" alt="Angry browser">
        </div>

        <h1>It looks like you’re using an ancient browser.</h1>
        <h2>Can you please not?</h2>

        <div class="modern-browser-cta">Get a proper browser and visit us again.</div>

        <div class="download-btn">
          <a href="https://outdatedbrowser.com" target="_blank">outdatedbrowser.com</a>
        </div>

      </div>
    </div>


  </section>
</template>

<script>
export default {
  name: 'OutdatedBrowser',

  props: {},

  data: function () {
    return {}
  },

  mounted() {
    document.getElementsByTagName('body')[0].classList.add('outdated')
  },

  components: {},

  computed: {},

  methods: {}
}


</script>