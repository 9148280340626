<template>
    <div class="mobile-menu-wrap">
        <div class="menu-wrap">
            <div class="menu-toggle" :class="{active: mobileMenuVisible}" @click="handleMobileMenuToggle">
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
        <transition name="fade">
            <div class="mobile-menu" v-if="mobileMenuVisible">
                <div class="container">
                    <div class="links">
                        <router-link to="/">home</router-link>
                        <router-link to="/company">company</router-link>
                        <CompanySubmenuMobile @navigate="handleSubmenuNavigate" />
                        <router-link to="/careers">careers</router-link>
                        <router-link to="/blog">blog</router-link>
                    </div>

                    <div class="mobile-menu-footer">
                        <transition name="fade">
                            <div class="social-links" v-if="menuFooterVisible.follow">
                                <div class="info-box">
                                    <h3>Follow us</h3>

                                    <SocialLinks />
                                </div>
                            </div>
                        </transition>

                        <div class="contact-info">
                            <transition name="fade">
                                <div class="info-box" v-if="menuFooterVisible.address">
                                    <h3>Location</h3>

                                    <div class="address">
                                        <p class="location">Kuzminečka 10
                                            <a href="https://goo.gl/maps/akahCct58nV7xCdB8" target="_blank"
                                               rel="noreferrer"><img class="location-ico"
                                                                     src="../../../assets/ic_location.svg"
                                                                     alt="Location"></a>
                                        </p>
                                        <p>10000 Zagreb </p>
                                    </div>
                                </div>
                            </transition>

                            <transition name="fade">
                                <div class="info-box" v-if="menuFooterVisible.contact">
                                    <h3>Contact</h3>

                                    <div class="contact">
                                        <p><a href="mailto:info@superology.com">info@superology.com</a></p>
                                        <p>+385 1 2090 383</p>
                                    </div>
                                </div>
                            </transition>
                        </div>
                    </div>
                </div>

            </div>
        </transition>
    </div>
</template>

<script>
import CompanySubmenuMobile from '@/components/layout/header/CompanySubmenuMobile.vue'
import SocialLinks from '@/components/layout/SocialLinks.vue'

export default {
    name: 'MobileMenu',

    props: {},

    data: function () {
        return {
            mobileMenuVisible: false,
            bodyRef: null,
            windowHeight: null,
            toggleSubmenu: false
        }
    },

    created() {
        window.addEventListener('resize', this.handleWindowHeight)
        this.handleWindowHeight()
    },

    mounted() {
        this.bodyRef = document.getElementsByTagName('body')[0]
    },

    components: {SocialLinks, CompanySubmenuMobile},

    computed: {
        menuFooterVisible() {

            if (this.$store.getters.getScreenWidth < 768) {
                return {
                    follow: this.windowHeight >= 620,
                    address: this.windowHeight >= 750,
                    contact: this.windowHeight >= 870
                }
            } else {
                const visible = this.windowHeight >= 800

                return {
                    follow: visible,
                    address: visible,
                    contact: visible
                }
            }
        },
    },

    methods: {
        handleMobileMenuToggle() {
            this.mobileMenuVisible = !this.mobileMenuVisible

            this.mobileMenuVisible ?
                this.bodyRef.classList.add('mobile-menu-opened') :
                this.bodyRef.classList.remove('mobile-menu-opened')

        },

        handleWindowHeight() {
            this.windowHeight = window.innerHeight
        },

        handleSubmenuNavigate() {
            setTimeout(() => {
                this.handleMobileMenuToggle()
            }, 0)
        }
    },

    watch: {
        '$route'() {
            this.mobileMenuVisible = false
            this.bodyRef.classList.remove('mobile-menu-opened')
        }
    },

    beforeDestroy() {
        this.bodyRef.classList.remove('mobile-menu-opened')
        document.removeEventListener('resize', this.handleWindowHeight)
    }
}


</script>
