<template>
    <div class="product-animation">
        <div class="showcase-tabs animated fadeIn" v-if="slidesVisible">
            <div class="tab" :class="{active: activeTab === 'stats'}" @click="setShowcaseSlide(0.0)">Stats</div>
            <div class="tab" :class="{active: activeTab === 'live'}" @click="setShowcaseSlide(0.25)">Live-betting</div>
            <div class="tab" :class="{active: activeTab === 'social'}" @click="setShowcaseSlide(0.5)">Social</div>
            <div class="tab" :class="{active: activeTab === 'stream'}" @click="setShowcaseSlide(0.75)">Streaming</div>
        </div>

        <ShowcaseMobileWrap
            ref="animation"
            v-if="isMobile && slidesVisible"
            :timelinePosition="timelinePosition"
            @setTimelinePosition="setTimelinePosition"
            @setActiveTab="setActiveTab"
        />

        <ShowcaseDesktopWrap
            ref="animation"
            v-else-if="slidesVisible"
            :timelinePosition="timelinePosition"
            @setTimelinePosition="setTimelinePosition"
        />
    </div>
</template>

<script>

import {gsap} from 'gsap'
import {ScrollTrigger} from 'gsap/ScrollTrigger'

import ShowcaseDesktopWrap from '@/components/home/showcase/ShowcaseDesktopWrap.vue'
import ShowcaseMobileWrap from '@/components/home/showcase/ShowcaseMobileWrap.vue'

gsap.registerPlugin(ScrollTrigger)

const MOBILE_VIEWPORT_TRIGGER = 1024

export default {
    name: 'ProductAnimation',

    props: {},

    data: function () {
        return {
            trigger: null,

            // leverage animation start on child component mount
            slidesVisible: false,
            timelinePosition: 0
        }
    },

    mounted() {
        this.initProductAnimate()
    },

    components: {
        ShowcaseDesktopWrap,
        ShowcaseMobileWrap,
    },

    computed: {
        isMobile() {
            return this.$store.getters.getScreenWidth < MOBILE_VIEWPORT_TRIGGER
        },

        activeTab() {
            if (this.timelinePosition >= 0.75) return 'stream'
            if (this.timelinePosition >= 0.5) return 'social'
            if (this.timelinePosition >= 0.25) return 'live'
            else return 'stats'
        }
    },

    methods: {
        initProductAnimate() {

            this.trigger = new gsap.timeline({
                scrollTrigger: {
                    trigger: '.product-showcase',
                    start: 'top 60%', // when the top of the trigger hits the top of the viewport
                    end: 'bottom 200px',
                    scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar,
                    onEnter: self => {
                        this.slidesVisible = true
                        if (this.$refs.animation) {
                            this.$refs.animation.resumeAnimation()
                        }
                    },
                    onEnterBack: self => {
                        if (this.$refs.animation) {
                            this.$refs.animation.resumeAnimation()
                        }
                    },
                    onLeave: self => {
                        if (this.$refs.animation) {
                            this.$refs.animation.pauseAnimation()
                        }
                    },
                    onLeaveBack: self => {
                        if (this.$refs.animation) {
                            this.$refs.animation.pauseAnimation()
                        }
                    }
                }
            })
        },

        setTimelinePosition(val) {
            this.timelinePosition = val
        },

        setShowcaseSlide(timelinePosition) {
            this.$refs.animation.setShowcaseSlide(timelinePosition)
        },

        setActiveTab(tab) {
            this.activeTab = tab
        }
    },

    beforeDestroy() {
        if (this.trigger) {
            this.trigger.kill()
        }
    }
}
</script>
