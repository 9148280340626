<template>
    <div class="team role animated fadeIn">
        <div class="hero-header standard careers">
            <div class="container">
                <CompanySubmenu />
                <TeamHeader>
                    <template v-slot:teamTitle>
                        <h1><span class="highlight-green">Data Analytics</span><br />team</h1>
                    </template>

                    <template v-slot:subHeader>
                        The answer gang
                    </template>

                    <template v-slot:jersey>
                        <img ref="jersey" src="../../assets/teams/jersey-team-data-analytics.svg"
                             alt="Data analytics jersey">
                    </template>
                </TeamHeader>
            </div>
        </div>

        <section class="about-team stripe-mask dark">
            <div class="container">
                <p class="team-description gs-animate fade enter">
                    A diverse, ambitious bunch set out to find the answer to the ultimate question. Our mission is to
                    explore the
                    vast world of the company’s data plains, map them, transform them into useful insights and provide
                    support so
                    we can all make great business decisions. This involves creating datasets, working with multiple
                    sources of
                    truth, using clustering methods to generate new knowledge and drive data quality efforts,
                    maintaining a
                    development data pipeline that ingests hundreds of thousands of new messages.<br /><br />

                    Like all true analysts, we like to optimize, test, and iterate our processes and the ways we learn
                    and work.
                    We provide the best data there is to make the best product there is.
                </p>
                <div class="team-details">
                    <div class="team-size gs-animate fade enter">
                        <p class="caption">Team size</p>
                        <p class="size highlight-green">7</p>
                    </div>
                    <div class="tech-stack gs-animate fade enter">
                        <p class="caption">Tech stack</p>
                        <div class="icons">
                            <img src="../../assets/teams/kafka.svg" alt="Kafka" title="Kafka">
                            <img src="../../assets/teams/vertica.svg" alt="Vertica" title="Vertica">
                            <img src="../../assets/teams/clickhouse.svg" alt="ClickHouse" title="ClickHouse">
                            <img src="../../assets/teams/big-query.svg" alt="BigQuery" title="BigQuery">
                            <img src="../../assets/teams/pandas.svg" alt="Pandas" title="Pandas">
                            <img src="../../assets/teams/jupyter.svg" alt="Jupyter" title="Jupyter">
                            <img src="../../assets/teams/plotly.svg" alt="Plotly" title="Plotly">
                            <img src="../../assets/teams/elk.svg" alt="ELK" title="ELK">
                            <img src="../../assets/teams/tableau.svg" alt="Tableau" title="Tableau">
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <CompanyCollage />

        <JoinTeamCTA v-if="team.jobActive" :team="team" />

        <NextSuperTeam :nextTeam="nextTeam" />
    </div>
</template>

<script>
import gsapReveal from '@/mixins/GsapReveal'

import TeamHeader from '@/components/teams/TeamHeader'
import CompanyCollage from '@/components/teams/CompanyCollage'
import NextSuperTeam from '@/components/teams/NextSuperTeam'
import JoinTeamCTA from '@/components/teams/JoinTeamCTA'
import CompanySubmenu from '@/components/company/CompanySubmenu.vue'
import metadata from '@/util/companyMetadata'

export default {
    name: 'DataAnalyticsTeam',

    mixins: [gsapReveal],

    metaInfo: {
        meta: metadata.dataAnalyticsTeam,
        title: 'Data Analytics team at Superology',
        link: [
            {rel: 'canonical', href: 'https://superology.com/company/data-analytics-team'}
        ]
    },

    components: {
        TeamHeader,
        CompanyCollage,
        NextSuperTeam,
        JoinTeamCTA,
        CompanySubmenu
    },

    computed: {
        team() {
            return this.$store.getters.getTeams['dataAnalyst']
        },
        nextTeam() {
            return this.team.nextTeam
        },
    },

    methods: {}
}
</script>
