<template>
    <div class="team role animated fadeIn">
        <div class="hero-header standard careers">
            <div class="container">
                <CompanySubmenu />
                <TeamHeader>
                    <template v-slot:teamTitle>
                        <h1><span class="highlight-green">People<br /> & Culture</span><br />team</h1>
                    </template>

                    <template v-slot:subHeader>
                        People’s people
                    </template>

                    <template v-slot:jersey>
                        <img ref="jersey" src="../../assets/teams/jersey-team-pnc.svg" alt="P&C jersey">
                    </template>
                </TeamHeader>
            </div>
        </div>

        <section class="about-team stripe-mask dark">
            <div class="container">
                <p class="team-description gs-animate fade enter">
                    Even though our work tends to be a bit subtler and a little less quantifiable than the output of
                    your average
                    tech team, our ever elusive methods are nevertheless seen and felt throughout. We believe that
                    translating
                    progressive ideas into everyday practices is what makes the company’s culture unique and empowering.
                    We’re of
                    the people, for the people. That’s our whole point.
                </p>
                <div class="team-details gs-animate fade enter">
                    <div class="team-size">
                        <p class="caption">Team size</p>
                        <p class="size highlight-green">8</p>
                    </div>
                    <div class="tech-stack gs-animate fade enter">
                        <p class="caption">Tech stack</p>
                        <div class="icons">
                            <img src="../../assets/teams/figma.svg" alt="Figma" title="Figma">
                            <img src="../../assets/teams/talent-lyft.svg" alt="TalentLyft" title="TalentLyft">
                            <img src="../../assets/teams/notion.svg" alt="Notion" title="Notion">
                            <img src="../../assets/teams/lattice.svg" alt="Lattice" title="Lattice">
                            <img src="../../assets/teams/adobe.svg" alt="Adobe" title="Adobe">
                            <img src="../../assets/teams/asana.svg" alt="Asana" title="Asana">
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <CompanyCollage />

        <JoinTeamCTA v-if="team.jobActive" :team="team" />

        <NextSuperTeam :nextTeam="nextTeam" />
    </div>
</template>

<script>
import gsapReveal from '@/mixins/GsapReveal'

import TeamHeader from '@/components/teams/TeamHeader'
import CompanyCollage from '@/components/teams/CompanyCollage'
import NextSuperTeam from '@/components/teams/NextSuperTeam'
import JoinTeamCTA from '@/components/teams/JoinTeamCTA'
import CompanySubmenu from '@/components/company/CompanySubmenu.vue'
import metadata from '@/util/companyMetadata'

export default {
    name: 'PeopleAndCultureTeam',

    mixins: [gsapReveal],

    metaInfo: {
        meta: metadata.peopleAndCultureTeam,
        title: 'People & Culture team at Superology',
        link: [
            {rel: 'canonical', href: 'https://superology.com/company/people-culture-team'}
        ]
    },

    components: {
        TeamHeader,
        CompanyCollage,
        NextSuperTeam,
        JoinTeamCTA,
        CompanySubmenu
    },

    computed: {
        team() {
            return this.$store.getters.getTeams['peopleCulture']
        },
        nextTeam() {
            return this.team.nextTeam
        },
    },

    methods: {}
}
</script>
