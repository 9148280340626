import Company from '@/views/Company.vue'
import DesignTeam from '@/views/teams/DesignTeam.vue'
import BackendTeam from '@/views/teams/BackendTeam.vue'
import DevOpsTeam from '@/views/teams/DevOpsTeam.vue'
import FrontendTeam from '@/views/teams/FrontendTeam.vue'
import DataAnalyticsTeam from '@/views/teams/DataAnalyticsTeam.vue'
import IOSTeam from '@/views/teams/iOSTeam.vue'
import AndroidTeam from '@/views/teams/AndroidTeam.vue'
import ProductManagementTeam from '@/views/teams/ProductManagementTeam.vue'
import QualityAssuranceTeam from '@/views/teams/QualityAssuranceTeam.vue'
import PeopleAndCultureTeam from '@/views/teams/PeopleAndCultureTeam.vue'
import OfficeTeam from '@/views/teams/OfficeTeam.vue'

export default [
    {
        path: '/company',
        name: 'Company',
        component: Company
    },
    {
        path: '/company/design-team',
        name: 'Design Team',
        component: DesignTeam
    },
    {
        path: '/company/backend-team',
        name: 'Backend Team',
        component: BackendTeam
    },
    {
        path: '/company/devops-team',
        name: 'DevOps Team',
        component: DevOpsTeam
    },
    {
        path: '/company/frontend-team',
        name: 'Frontend Team',
        component: FrontendTeam
    },
    {
        path: '/company/data-analytics-team',
        name: 'Data Analytics Team',
        component: DataAnalyticsTeam
    },
    {
        path: '/company/ios-team',
        name: 'iOS Team',
        component: IOSTeam
    },
    {
        path: '/company/android-team',
        name: 'Android Team',
        component: AndroidTeam
    },
    {
        path: '/company/product-management-team',
        name: 'Product Management Team',
        component: ProductManagementTeam
    },
    {
        path: '/company/qa-team',
        name: 'Quality Assurance Team',
        component: QualityAssuranceTeam
    },
    {
        path: '/company/people-culture-team',
        name: 'People & Culture Team',
        component: PeopleAndCultureTeam
    },
    {
        path: '/company/office-team',
        name: 'Office Team',
        component: OfficeTeam
    },
]
