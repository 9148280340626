export default {
  enterRight: {
    from: {x: -90, opacity: 0},
    to: {x: 0, opacity: 1}
  },
  enterLeft: {
    from: {x: 90, opacity: 0},
    to: {x: 0, opacity: 1}
  },
  enterUp: {
    from: {y: 60, opacity: 0},
    to: {y: 0, opacity: 1, duration: 1}
  },

  pauseTime: 5,
  fadeoutTime: 0.4
}