<template>
  <div class="menu-wrap">
    <router-link to="/">home</router-link>
    <router-link to="/company">company</router-link>
    <router-link to="/careers">careers</router-link>
    <router-link to="/blog">blog</router-link>
  </div>
</template>

<script>
export default {
  name: 'DesktopMenu',

  props: {},

  data: function () {
    return {}
  },

  created() {

  },

  components: {},

  computed: {},

  methods: {}
}


</script>
