<template>
    <div class="showcase-view-desktop">
        <div class="showcase-image">
            <img class="animated fadeIn" :src="activeBulletImage" :srcset="activeBulletImageSizes" alt="Superology app">
        </div>

        <div class="showcase-content-wrap">
            <div class="showcase-heading">
                <div class="showcase-bullet">
                    <div class="ico">
                        <img src="../../assets/company/icon-working.svg" alt="Product timeline">
                    </div>
                    <h2 class="fade enter">
                        How our product evolved through the years
                    </h2>
                </div>
            </div>

            <div class="showcase-content">
                <div class="bullets-wrap-desktop">
                    <ShowcaseBullet
                        v-for="(value, key) in companyProductShowcase"
                        :bulletYear="key"
                        :activeBulletYear="activeBulletYear"
                        :key="key"
                        @setActiveBullet="setActiveBullet"
                    />
                </div>
                <div class="bullets-content-wrap">
                    <p class="description">{{ activeBulletDescription }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ShowcaseBullet from '@/components/company/ShowcaseBullet.vue'
import TimelineBulletImage from '@/mixins/TimelineBulletImage.js'

export default {
    name: 'ShowcaseAnimationDesktop',

    data: function () {
        return {
            activeBulletYear: '2012'
        }
    },

    mixins: [TimelineBulletImage],

    components: {ShowcaseBullet},

    computed: {
        companyProductShowcase() {
            return this.$store.getters.getCompanyProductShowcase
        },
        activeBulletDescription() {
            return this.companyProductShowcase[this.activeBulletYear]
        },
    },

    methods: {
        setActiveBullet(bullet) {
            this.activeBulletYear = bullet
        }
    },
}
</script>
