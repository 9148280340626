<template>
  <div class="bullet-point" :class="{ 'active' : isActive }" @click="setActiveBullet">
    <div class="bullet"></div>
    <h3 class="year">{{ bulletYear }}</h3>
  </div>
</template>

<script>
export default {
  name: "ShowcaseBullet",
  props: {
    bulletYear: {
      type: String,
      required: true
    },

    activeBulletYear: {
      type: String,
      required: true
    }
  },

  computed: {
    isActive() {
      return this.activeBulletYear === this.bulletYear
    }
  },

  methods: {
    setActiveBullet() {
      this.$emit('setActiveBullet', this.bulletYear)
    }
  }
}
</script>
