<template>
  <div class="showcase-desktop-wrap animated fadeIn">
    <div class="showcase-scene" ref="showcaseStats">
      <div class="showcase-wrap stats">
        <div class="phone" ref="statsPhone">
          <img
            rel="prefetch"
            src="../../../assets/homepageShowcase/stats-phone.png"
            srcset="../../../assets/homepageShowcase/2x-stats-phone.png 2x, ../../../assets/homepageShowcase/3x-stats-phone.png 3x"
            alt="Phone">
        </div>
        <img rel="prefetch" class="bubble-text" ref="statsBubbletext"
             src="../../../assets/homepageShowcase/stats-bubble-desktop.png"
             srcset="../../../assets/homepageShowcase/2x-stats-bubble-desktop.png 2x, ../../../assets/homepageShowcase/3x-stats-bubble-desktop.png 3x"
             alt="Bubble text">
        <div class="hero-text" ref="statsHerotext">
          <div class="big-text">300+</div>
          <div class="small-text">different kinds of statistics</div>
        </div>
      </div>
    </div>

    <div class="showcase-scene" ref="showcaseLive">
      <div class="showcase-wrap live">
        <div class="phone" ref="livePhone">
          <img
            rel="prefetch"
            src="../../../assets/homepageShowcase/live-phone.png"
            srcset="../../../assets/homepageShowcase/2x-live-phone.png 2x, ../../../assets/homepageShowcase/3x-live-phone.png 3x"
            alt="Phone">
        </div>
        <img rel="prefetch" class="bubble-text" ref="liveBubbletext"
             src="../../../assets/homepageShowcase/live-bubble-desktop.png"
             srcset="../../../assets/homepageShowcase/2x-live-bubble-desktop.png 2x, ../../../assets/homepageShowcase/3x-live-bubble-desktop.png 3x"
             alt="Bubble text">
        <div class="hero-text" ref="liveHerotext">
          <div class="big-text">30+</div>
          <div class="small-text">sports (and esports)</div>
        </div>
      </div>
    </div>

    <div class="showcase-scene" ref="showcaseSocial">
      <div class="showcase-wrap social">
        <div class="phone" ref="socialPhone">
          <img
            rel="prefetch"
            src="../../../assets/homepageShowcase/social-phone.png"
            srcset="../../../assets/homepageShowcase/2x-social-phone.png 2x, ../../../assets/homepageShowcase/3x-social-phone.png 3x"
            alt="Phone">
        </div>
        <img rel="prefetch" class="bubble-text" ref="socialBubbletext"
             src="../../../assets/homepageShowcase/social-bubble-desktop.png"
             srcset="../../../assets/homepageShowcase/2x-social-bubble-desktop.png 2x, ../../../assets/homepageShowcase/3x-social-bubble-desktop.png 3x"
             alt="Bubble text">
        <div class="hero-text" ref="socialHerotext">
          <div class="big-text">710k</div>
          <div class="small-text">users on SuperSocial</div>
        </div>
      </div>
    </div>

    <div class="showcase-scene" ref="showcaseStream">
      <div class="showcase-wrap stream">
        <div class="phone" ref="streamPhone">
          <img
            rel="prefetch"
            src="../../../assets/homepageShowcase/stream-phone.png"
            srcset="../../../assets/homepageShowcase/2x-stream-phone.png 2x, ../../../assets/homepageShowcase/3x-stream-phone.png 3x"
            alt="Phone">
        </div>
        <img rel="prefetch" class="bubble-text" ref="streamBubbletext"
             src="../../../assets/homepageShowcase/stream-bubble-desktop.png"
             srcset="../../../assets/homepageShowcase/2x-stream-bubble-desktop.png 2x, ../../../assets/homepageShowcase/3x-stream-bubble-desktop.png 3x"
             alt="Bubble text">
        <div class="hero-text" ref="streamHerotext">
          <div class="hero-text-row">
            <div class="big-text">7,1</div>
            <div class="small-text">million <br> downloads</div>
          </div>
          <div class="hero-text-row">
            <div class="big-text">4,7</div>
            <div class="small-text">on <br> App store</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import animateShowcase from '@/components/home/showcase/animateShowcase'

export default {
  name: 'ShowcaseDesktopWrap',

  props: {
    timelinePosition: {
      required: true,
      type: Number
    }
  },

  data: function () {
    return {}
  },

  mixins: [animateShowcase],

  components: {},

  computed: {},

  methods: {}
}


</script>
