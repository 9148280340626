<template>
    <section id="open-positions" class="role-cards">
        <div class="container">
            <div class="gs-animate fade enter">
                <h2>Superology has joined Happening in April 2023.<br /> Here’s where you’ll find us from now on.</h2>

                <a class="bullet-btn visit-happening" href="https://happening.xyz">Visit Happening</a>
            </div>
        </div>
    </section>
</template>

<script>


export default {
    name: 'OpenPositions',

    props: {},

    data: function () {
        return {
            hoverColor: '#020711',

        }
    },

    created() {

    },

    components: {},

    computed: {},

    methods: {}
}


</script>
