<template>
    <div class="team role animated fadeIn">
        <div class="hero-header standard careers">
            <div class="container">
                <CompanySubmenu />
                <TeamHeader>
                    <template v-slot:teamTitle>
                        <h1><span class="highlight-green">QA</span><br />team</h1>
                    </template>

                    <template v-slot:subHeader>
                        Test, improve, repeat
                    </template>

                    <template v-slot:jersey>
                        <img ref="jersey" src="../../assets/teams/jersey-team-qa.svg" alt="QA jersey">
                    </template>
                </TeamHeader>
            </div>
        </div>

        <section class="about-team stripe-mask dark">
            <div class="container">
                <p class="team-description gs-animate fade enter">
                    Do we want to solve problems before they reach end users? Do we want to stop and prevent bugs in an
                    early
                    stage of development? Is a great product our end goal? Yes, yes, and yes! We are QA engineers. We
                    test
                    applications throughout the whole SDLC process and make sure every base is covered. We write test
                    scenarios,
                    coordinate with the team and work closely with product managers and designers. We do it all with
                    good humor.
                    Only by improving our processes and ourselves can we deliver a great product to our users.
                </p>
                <div class="team-details gs-animate fade enter">
                    <div class="team-size">
                        <p class="caption">Team size</p>
                        <p class="size highlight-green">12</p>
                    </div>
                    <div class="tech-stack gs-animate fade enter">
                        <p class="caption">Tech stack</p>
                        <div class="icons">
                            <img src="../../assets/teams/testa.svg" alt="Testa" title="Testa">
                            <img src="../../assets/teams/ruby.svg" alt="Ruby" title="Ruby">
                            <img src="../../assets/teams/jira-xray.svg" alt="Xray Jira" title="Xray Jira">
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <CompanyCollage />

        <JoinTeamCTA v-if="team.jobActive" :team="team" />

        <NextSuperTeam :nextTeam="nextTeam" />
    </div>
</template>

<script>
import gsapReveal from '@/mixins/GsapReveal'

import TeamHeader from '@/components/teams/TeamHeader'
import CompanyCollage from '@/components/teams/CompanyCollage'
import NextSuperTeam from '@/components/teams/NextSuperTeam'
import JoinTeamCTA from '@/components/teams/JoinTeamCTA'
import CompanySubmenu from '@/components/company/CompanySubmenu.vue'
import metadata from '@/util/companyMetadata'

export default {
    name: 'QualityAssuranceTeam',

    mixins: [gsapReveal],

    metaInfo: {
        meta: metadata.qualityAssuranceTeam,
        title: 'Quality Assurance team at Superology',
        link: [
            {rel: 'canonical', href: 'https://superology.com/company/qa-team'}
        ]
    },

    components: {
        TeamHeader,
        CompanyCollage,
        NextSuperTeam,
        JoinTeamCTA,
        CompanySubmenu
    },

    computed: {
        team() {
            return this.$store.getters.getTeams['qa']
        },
        nextTeam() {
            return this.team.nextTeam
        },
    },

    methods: {}
}
</script>
