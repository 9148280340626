import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import VueMeta from 'vue-meta'
import VueScrollTo from 'vue-scrollto'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/swiper.scss'
Vue.config.productionTip = false

require('./scss/main.scss')

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})

Vue.use(VueScrollTo)
Vue.use(VueAwesomeSwiper)

new Vue({
  router,
  store,
  render: h => h(App),
  created(){
    this.handleCookies()
  },
  computed: {
    cookieStorageKey(){
      return this.$store.getters.getCookieStorageKey
    }
  },
  methods: {
    handleCookies(){
      // check localstorage
      const cookiePreferences = localStorage.getItem(this.cookieStorageKey)

      if(cookiePreferences){
        this.$store.commit('setCookiePreferences', JSON.parse(cookiePreferences))
        this.$store.dispatch('initCookiePreferences')
      }
      else{
        this.$store.commit('setCookiePopupVisible', true)
      }
    }
  }

}).$mount('#superology')
