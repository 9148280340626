<template>
  <div class="role gs-animate fade enter">
    <router-link :to="team.teamUrl" class="bubble"
      @mouseover.native="isHovered = true" @mouseleave.native="isHovered = false"
    >
      <div class="details">
        <h3>{{ team.teamName }}</h3>
      </div>
      <div class="apply-now">
        <ApplyArrow :hoverColor="hoverColor"/>
        view team
      </div>
    </router-link>
  </div>
</template>

<script>
import ApplyArrow from "../careers/ApplyArrow"

export default {
  name: "TeamBubble",
  props: {
    team: {
      type: Object,
      required: true
    }
  },

  data: function () {
    return {
      isHovered: false
    }
  },

  components: {ApplyArrow},

  computed: {
    hoverColor() {
      const colors = {
        hover: '#d1cb19',
        default: '#020711'
      }

      return this.isHovered ? colors.hover : colors.default
    }
  }
}
</script>
