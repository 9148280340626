<template>
  <!--  we haven't yet configure route positions -->
  <a href="#"
     v-if="isCompanyPage"
     :class="{'active' : getActiveAnchor === link}"
     @click="scrollToElement"
     v-scroll-to="anchorSettings"
  >
    {{ link }}
  </a>
  <router-link
      v-else
      :to="{name: 'Company', hash: `#${link}`}"
      :class="{'active' : getActiveAnchor === link}"
  >
    {{ link }}
  </router-link>
</template>

<script>
export default {
  name: 'SubmenuLink',

  props: {
    link: {
      type: String
    }
  },

  data() {
    return {}
  },

  mounted() {
    if (!this.isCompanyPage) {
      this.$store.commit('setCompanySubMenuLink', '')
    }
  },

  computed: {
    scrollToSettings() {
      return this.$store.getters.getScrollToSettings
    },
    anchorSettings() {
      return {
        el: this.getElementId,
        ...this.scrollToSettings
      }
    },
    getActiveAnchor() {
      return this.$store.getters.getCompanySubMenuLink
    },
    isCompanyPage() {
      return this.$router.currentRoute.name === 'Company'
    },
    getElementId() {
      return this.getActiveAnchor === 'about' ? '#superology' : `#${this.getActiveAnchor}`
    }
  },

  methods: {
    scrollToElement() {
      this.$store.commit('setCompanySubMenuLink', this.link)
      this.$emit('navigate')
    }
  }
}
</script>
