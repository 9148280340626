<template>
    <div class="not-found animated fadeIn">
        <section class="hero-header standard">
            <div class="container">
                <h1><span class="highlight-green">404</span></h1>
                <p class="notification-404">You were probably looking for something that isn’t there anymore...</p>
            </div>
        </section>

        <section class="message-404 stripe-mask dark">

        </section>
    </div>

</template>

<script>
export default {
    name: 'NotFound404',

    props: {},

    data: function () {
        return {
            meta: {
                title: '404 - Page not found',
                description: 'The page you are looking for cannot be found',
                ogImage: 'https://superology.com/notfoundOgImg.jpg'
            }
        }
    },

    created() {
    },

    components: {},

    computed: {},

    methods: {}
}


</script>
