<template>
  <div class="company-submenu-mobile">
    <SubmenuLink :link="link" v-for="link in links" :key="link" v-on="$listeners"/>
  </div>
</template>

<script>
import SubmenuLink from "../../company/SubmenuLink.vue"

export default {
  name: "CompanySubmenuMobile",
  components: {SubmenuLink},

  computed: {
    links() {
      return this.$store.getters.getCompanySubMenuLinks
    }
  }
}
</script>
