<template>
  <div class="follow-links">
    <p v-for="social in socialLinks"><a :href="social.url" target="_blank" rel="noreferrer">{{ social.title }}</a></p>
  </div>
</template>

<script>
export default {
  name: 'SocialLinks',

  props: {},

  data: function () {
    return {}
  },

  created() {

  },

  components: {},

  computed: {
    socialLinks(){
      return this.$store.getters.getSocialLinks
    }
  },

  methods: {}
}
</script>
