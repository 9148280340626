import {gsap} from 'gsap'
import {ScrollTrigger} from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

export default {
  data: function(){
    return {
      triggers: []
    }
  },
  mounted() {

    setTimeout(() => {

      const elements = gsap.utils.toArray('.gs-animate')

      elements.forEach(element  => {
        const trigger = ScrollTrigger.create({
          trigger: element,
          toggleClass: 'visible',
          start: 'top 93%',
          end: 'bottom',
          scrub: 1,
          // markers: true,
          once: true,
        })

        this.triggers.push(trigger)
      })
    }, 20)

  },

  beforeDestroy(){
    this.triggers.forEach(trigger => trigger.kill())
  }
}
