<template>
    <div class="job-fair-contest animated fadeIn">
        <section class="hero-header standard">
            <div class="container">
                <h1>Job Fair<br><span class="highlight-green">Contest 2022</span></h1>
            </div>
        </section>

        <section class="content legal stripe-mask dark">
            <div class="container">
                <div class="narrow-wrap">



                    <div class="contest-finished">
                        <h2>Game, Set, Match!</h2>
                        <p>The last round of the competition is over!</p>

                        <p>We congratulate our winners, and we give thanks to all of the competitors for playing!</p>

                        <p>Hope to see you next year again!</p>

                        <p>Winners:</p>

                        <ul class="winners-list">
                            <li><strong>1. place - Karlo Josip Kardum -  PlayStation 5 bundle with 3 games</strong></li>
                            <li>2. place - Ivo Veverec - Headphones Sony WH-1000XM3/B</li>
                            <li>3. place - Ivan Karlović - Keyboard Logitech G512 gaming GX red</li>
                        </ul>

                        <img class="contest-winners" src="../assets/jobfair/job-fair-contest-2022-winners.jpg" alt="Job Fair 2022 winners">
                    </div>

                    <p>Superology is organising a tennis tournament, and we want to invite as many players as possible.
                        The catch
                        is that no two players competing in this tournament should have played with each other in the
                        past.</p>

                    <p>Download the <strong>matches.txt</strong> file (see below) that contains the list of tennis
                        matches,
                        with participating players’ IDs and names. You are allowed to use only the matches and players
                        from this
                        file.</p>

                    <p>Your job is to find the largest possible set of such players and send it in the form of a players
                        ID list,
                        comma-separated in a .txt file (without spaces).</p>

                    <p>For example: 123,456,789.</p>

                    <p>Name the file in the format: <strong>name-surname-JMBAG.txt</strong>.</p>

                    <p>Submit the file and your personal information (name, surname, JMBAG, university, or faculty you
                        attend) to
                        <a href="mailto:jobfaircontest2022@superology.com">jobfaircontest2022@superology.com</a>.</p>

                    <p>The deadline for submitting your final solution is <strong>May 30, 2022, 23:59 PM</strong>.</p>

                    <p>We will get back to winners no later than June 7, 2022.</p>

                    <p>Only people whose personal data is in this year’s Job Fair database can participate in the
                        competition for
                        the prizes.</p>

                    <p>Prizes are:</p>

                    <ul>
                        <li><strong>The main prize - PlayStation 5 bundle with 3 games</strong></li>
                        <li>Second prize - Headphones Sony WH-1000XM3/B</li>
                        <li>Third prize - Keyboard Logitech G512 gaming GX red</li>
                    </ul>

                    <p>One person can upload one answer. If you upload more than one, the last submitted solution will
                        be
                        considered valid, and the others will be ignored. An expert jury composed of Superology’s
                        employees will
                        decide on the best answers. If there should be two or more candidates with the same answer,
                        another round of
                        selection may be applied.</p>

                    <p>If you have any additional questions, please send them to
                        <a href="mailto:jobfaircontest2022@superology.com">jobfaircontest2022@superology.com</a></p>

                    <h2>Consent policy</h2>

                    <p>Your personal data will be stored for a necessary and strictly defined period of time for the
                        purposes of participation in prize contest, and for promotional sending of marketing
                        communication for the similar events or campaigns, as well as for potential job vacancies in
                        accordance with applicable regulations and laws governing personal data protection.</p>

                    <p>By submitting the file and your personal information as explained above in the text </p>

                    <p>a) You agree that your personal data is used for the above listed marketing communication</p>

                    <p>b) You agree to store your personal data and be contacted via email for the purposes of HR
                        selection of potential candidates for job vacancies.</p>

                    <p>We will store your personal information:</p>

                    <ul>
                        <li>Based on your explicit consent to the processing of personal data, and for the duration of
                            your consent, in any case no longer that 1 year from the moment you have provide us with
                            consent (i.e. when submitted file and your personal information)
                        </li>
                        <li>Until you request the exercise of your “right to delete” or “right to portability” of data
                            in accordance with the GDPR, unless there is another legal basis for continuing to retain
                            personal data.
                        </li>
                        <li>You understand that at any time in the future you can request the deletion of your personal
                            data, as well as the exercise of other rights in accordance with the GDPR, by submitting a
                            request to email data.privacy@superbet.hr
                        </li>
                        <li>You've read the full privacy notice.</li>
                    </ul>

                </div>
            </div>
        </section>
    </div>
</template>

<script>

export default {
    name: 'JobFairContest2022',

    metaInfo: {
        meta: [
            {
                property: 'og:description',
                content: 'Find the solution to our problem to win a PS5 and other prizes!'
            },
            {
                name: 'description',
                content: 'Find the solution to our problem to win a PS5 and other prizes!'
            },
            {
                property: 'og:image',
                content: 'https://superology.com/static/ogimg-jobfair-ps5.png'
            },
            {
                property: 'og:type',
                content: 'article'
            },
            {
                property: 'og:title',
                content: 'Superology - Job Fair Contest 2022'
            },
            {
                property: 'og:url',
                content: 'https://superology.com/job-fair-contest-2022'
            }
        ],
        title: 'Superology - Job Fair Contest 2022',
        link: [
            {rel: 'canonical', href: 'https://superology.com/job-fair-contest-2022'}
        ]
    },

    props: {},

    data: function () {
        return {
            consent: {
                marketing: false,
                hrSelection: false,
                personalDataAcknowledge: false,
                privacyNoticeRead: false
            }
        }
    },

    created() {

    },

    components: {},

    computed: {
        downloadDisabled() {
            return Object.values(this.consent).some(value => value === false)
        }
    },

    methods: {}
}
</script>

<style lang="scss" scoped>
/* Styles placed here since this is a temporary page so we don't pollute regular style files */
@import "../scss/variables";

.bullet-btn {
    border: none;
    cursor: pointer;
    margin-top: 16px;
    transition: all 0.3s;
    display: inline-block;
    height: initial;
    text-align: left;

    &:hover {
        text-decoration: none;
    }

    &:disabled {
        background: $botticelli;
        color: $regentGrey;


        &:hover {
            padding-left: 20px;
            cursor: default;
        }
    }
}

.content{
    h2{
        padding: 0 0 10px 0;
    }
}

.contest-finished{
    padding: 30px;
    margin-bottom: 20px;
    box-sizing: border-box;
    border-radius: 22px;
    background: $botticelli;

    p{
        &:last-child{
            margin-bottom: 0;
        }
    }
}

.contest-winners{
    display: block;
    width: 100%;
    border-radius: 4px;
}

.winners-list{
    list-style: none;
    padding: 0;
}
</style>
