import Vue from 'vue'
import Router from 'vue-router'
import NotFound404 from '@/views/NotFound404.vue'

import generalRoutes from '@/router/routes/general'
import companyRoutes from '@/router/routes/company'
import careersRoutes from '@/router/routes/careers'
import blogRoutes from '@/router/routes/blog'


Vue.use(Router)

const routes = [
    ...generalRoutes,
    ...companyRoutes,
    ...careersRoutes,
    ...blogRoutes,
    {
        path: '*',
        name: 'Not found',
        component: NotFound404
    }
]

const router = new Router({
    scrollBehavior(to, from, savedPosition) {

        if (savedPosition) {
            return savedPosition
        } else if (to.hash) {
            return {selector: to.hash}
        } else if (from.path === to.path) {
            return {}
        }

        return {x: 0, y: 0}
    },
    mode: 'history',
    linkExactActiveClass: 'exact-active',
    linkActiveClass: 'active',
    base: '/',
    routes
})

router.beforeEach((to, from, next) => {

    const ua = navigator.userAgent
    const isIE = ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1

    // nag IE users on every navigation ୧(ಠ益ಠ)୨
    if (isIE && to.name !== 'Outdated browser') {
        next('/can-you-not')
    }

    // hide the outdated browser for any other normal person
    else if (!isIE && to.name === 'Outdated browser') {
        next('/page-not-found')
    } else {
        next()
    }

})

export default router
