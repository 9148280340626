<template >
  <div class="legal animated fadeIn">
    <section class="hero-header standard">
      <div class="container">
        <h1><span class="highlight-green">Legal</span> stuff</h1>
      </div>
    </section>

    <section class="content legal stripe-mask dark">
      <div class="container">
        <div class="narrow-wrap">
          <p><span class="highlight-green"><strong>Superology d.o.o.</strong></span> is a limited company for information
            services, Kuzminečka ulica 10, 10000 Zagreb, Croatia. The company is registered with the Trade Court in Zagreb
            under MBS number 080800684. Bank Account No. HR9824070001100391800 with OTP banka Hrvatska d.d., Domovinskog
            rata 61, 21000 Split, Hrvatska. Base capital in the amount of 826.000,00 HRK paid in full. Members of the management board: Ivan Lulić, Petar Kujundžić</p>

          <h2>Privacy statement</h2>

          <p>In some instances, we might collect your personal information. Read our full privacy statement <router-link to="/privacy-statement">here</router-link>.</p>

          <h2>Analytics</h2>

          <p>We use a third party service, Google Analytics, to collect standard internet log information and details of
            visitor behaviour patterns (e.g. number of visitors to the various parts of our web page) in a way which does
            not identify anyone. More info <a href="https://policies.google.com/" target="_blank">here</a>.</p>

          <h2>Cookies</h2>

          <p>More information can be found in our <router-link to="/cookie-policy">Cookie Policy</router-link>.</p>
        </div>
      </div>
    </section>
  </div>

</template>

<script>

import metadata from '@/util/metadata'

export default {
  name: 'Legal',

  props: {},

  data: function () {
    return {

    }
  },

  metaInfo: {
    meta: metadata.legal,
    title: 'Legal | Superology',
    link: [
      {rel: 'canonical', href: 'https://superology.com/legal'}
    ]
  },

  created() {
  },

  components: {},

  computed: {},

  methods: {}
}


</script>
