const defaultMeta = {
    ogType: {
        property: 'og:type',
        content: 'article'
    },
    twitterCard: {
        name: 'twitter:card',
        content: 'summary_large_image'
    },
    ogImage: {
        property: 'og:image',
        content: 'https://superology.com/static/ogimg.jpg'
    },
    twitterImage: {
        name: 'twitter:image',
        content: 'https://superology.com/static/ogimg.jpg'
    },
}

const description = {
    company: 'Who we are, how we got here, and what drives us.',
    team: 'Meet the team'
}

export default {
    company: [
        {
            property: 'og:url',
            content: 'https://superology.com/company'
        },
        defaultMeta.ogType,
        {
            property: 'og:title',
            content: 'Company | Superology'
        },
        {
            property: 'og:description',
            content: description.company
        },
        {
            name: 'description',
            content: description.company
        },
        defaultMeta.ogImage,
        defaultMeta.twitterImage,
        defaultMeta.twitterCard
    ],

    androidTeam: [
        {
            property: 'og:url',
            content: 'https://superology.com/company/android-team'
        },
        defaultMeta.ogType,
        {
            property: 'og:title',
            content: 'Android team | Superology'
        },
        {
            property: 'og:description',
            content: description.team
        },
        {
            name: 'description',
            content: description.team
        },
        defaultMeta.ogImage,
        defaultMeta.twitterImage,
        defaultMeta.twitterCard
    ],

    backendTeam: [
        {
            property: 'og:url',
            content: 'https://superology.com/company/backend-team'
        },
        defaultMeta.ogType,
        {
            property: 'og:title',
            content: 'Backend team | Superology'
        },
        {
            property: 'og:description',
            content: description.team
        },
        {
            name: 'description',
            content: description.team
        },
        defaultMeta.ogImage,
        defaultMeta.twitterImage,
        defaultMeta.twitterCard
    ],

    dataAnalyticsTeam: [
        {
            property: 'og:url',
            content: 'https://superology.com/company/data-analytics-team'
        },
        defaultMeta.ogType,
        {
            property: 'og:title',
            content: 'Data Analytics team | Superology'
        },
        {
            property: 'og:description',
            content: description.team
        },
        {
            name: 'description',
            content: description.team
        },
        defaultMeta.ogImage,
        defaultMeta.twitterImage,
        defaultMeta.twitterCard
    ],

    designTeam: [
        {
            property: 'og:url',
            content: 'https://superology.com/company/design-team'
        },
        defaultMeta.ogType,
        {
            property: 'og:title',
            content: 'Design team | Superology'
        },
        {
            property: 'og:description',
            content: description.team
        },
        {
            name: 'description',
            content: description.team
        },
        defaultMeta.ogImage,
        defaultMeta.twitterImage,
        defaultMeta.twitterCard
    ],

    devOpsTeam: [
        {
            property: 'og:url',
            content: 'https://superology.com/company/devops-team'
        },
        defaultMeta.ogType,
        {
            property: 'og:title',
            content: 'DevOps team | Superology'
        },
        {
            property: 'og:description',
            content: description.team
        },
        {
            name: 'description',
            content: description.team
        },
        defaultMeta.ogImage,
        defaultMeta.twitterImage,
        defaultMeta.twitterCard
    ],

    frontendTeam: [
        {
            property: 'og:url',
            content: 'https://superology.com/company/frontend-team'
        },
        defaultMeta.ogType,
        {
            property: 'og:title',
            content: 'Frontend team | Superology'
        },
        {
            property: 'og:description',
            content: description.team
        },
        {
            name: 'description',
            content: description.team
        },
        defaultMeta.ogImage,
        defaultMeta.twitterImage,
        defaultMeta.twitterCard
    ],

    iosTeam: [
        {
            property: 'og:url',
            content: 'https://superology.com/company/ios-team'
        },
        defaultMeta.ogType,
        {
            property: 'og:title',
            content: 'iOS team | Superology'
        },
        {
            property: 'og:description',
            content: description.team
        },
        {
            name: 'description',
            content: description.team
        },
        defaultMeta.ogImage,
        defaultMeta.twitterImage,
        defaultMeta.twitterCard
    ],

    officeTeam: [
        {
            property: 'og:url',
            content: 'https://superology.com/company/office-team'
        },
        defaultMeta.ogType,
        {
            property: 'og:title',
            content: 'Office team | Superology'
        },
        {
            property: 'og:description',
            content: description.team
        },
        {
            name: 'description',
            content: description.team
        },
        defaultMeta.ogImage,
        defaultMeta.twitterImage,
        defaultMeta.twitterCard
    ],

    peopleAndCultureTeam: [
        {
            property: 'og:url',
            content: 'https://superology.com/company/people-culture-team'
        },
        defaultMeta.ogType,
        {
            property: 'og:title',
            content: 'People & Culture team | Superology'
        },
        {
            property: 'og:description',
            content: description.team
        },
        {
            name: 'description',
            content: description.team
        },
        defaultMeta.ogImage,
        defaultMeta.twitterImage,
        defaultMeta.twitterCard
    ],

    productManagementTeam: [
        {
            property: 'og:url',
            content: 'https://superology.com/company/product-management-team'
        },
        defaultMeta.ogType,
        {
            property: 'og:title',
            content: 'Product management team | Superology'
        },
        {
            property: 'og:description',
            content: description.team
        },
        {
            name: 'description',
            content: description.team
        },
        defaultMeta.ogImage,
        defaultMeta.twitterImage,
        defaultMeta.twitterCard
    ],

    qualityAssuranceTeam: [
        {
            property: 'og:url',
            content: 'https://superology.com/company/qa-team'
        },
        defaultMeta.ogType,
        {
            property: 'og:title',
            content: 'Quality Assurance team | Superology'
        },
        {
            property: 'og:description',
            content: description.team
        },
        {
            name: 'description',
            content: description.team
        },
        defaultMeta.ogImage,
        defaultMeta.twitterImage,
        defaultMeta.twitterCard
    ]
}
