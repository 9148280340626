import Vue from 'vue'
import Vuex from 'vuex'
import {initGtag, initGoogleAnalytics} from '../util/analyticScripts'

Vue.use(Vuex)

import careerRoutes from '@/router/careerRoutes'

const store = new Vuex.Store({
    state: {
        screenWidth: null,
        socialLinks: [
            {
                url: 'https://www.facebook.com/SuperologyTech',
                title: 'facebook'
            },
            {
                url: 'https://www.instagram.com/superology_tech/',
                title: 'instagram'
            },
            {
                url: 'https://hr.linkedin.com/company/superology',
                title: 'linkedin'
            }
        ],
        greenhouseJobs: {
            android: 4103783101,
            backend: 4104138101,
            ios: 4099407101,
            uxResearcher: 4113428101,
            web: 4106457101,
            devops: 4104683101,
            openApplication: 4106232101,
            kotlinMultiplatform: 4145838101,
            javascriptDeveloper: 4145865101,
            productDesigner: 4106238101, // | job inactive
            productDesignerDesignSystems: 75750, // MISSING GH ID | job inactive
            qa: 4104458101, // | job inactive
            dataAnalyst: 4106225101, // | job inactive
            pm: 36413, // MISSING GH ID | job inactive
        },
        teams: {
            android: {
                jobActive: false,
                jobUrl: careerRoutes.androidDeveloper,
                teamUrl: '/company/android-team',
                teamName: 'Android Team',
                nextTeam: {
                    name: 'Product Management',
                    url: '/company/product-management-team'
                }
            },
            backend: {
                jobActive: false,
                jobUrl: careerRoutes.backendDeveloper,
                teamUrl: '/company/backend-team',
                teamName: 'Backend Team',
                nextTeam: {
                    name: 'DevOps',
                    url: '/company/devops-team'
                }
            },
            iOS: {
                jobActive: false,
                jobUrl: careerRoutes.iosDeveloper,
                teamUrl: '/company/iOS-team',
                teamName: 'iOS Team',
                nextTeam: {
                    name: 'Android',
                    url: '/company/android-team'
                }
            },
            productDesigner: {
                jobActive: false,
                jobUrl: careerRoutes.productDesigner,
                teamUrl: '/company/design-team',
                teamName: 'Design Team',
                nextTeam: {
                    name: 'Backend',
                    url: '/company/backend-team'
                }
            },
            web: {
                jobActive: false,
                jobUrl: careerRoutes.frontendWebDeveloper,
                teamUrl: '/company/frontend-team',
                teamName: 'Frontend Team',
                nextTeam: {
                    name: 'Data Analytics',
                    url: '/company/data-analytics-team'
                }
            },
            qa: {
                jobActive: false,
                jobUrl: careerRoutes.qaEngineer,
                teamUrl: '/company/qa-team',
                teamName: 'QA Team',
                nextTeam: {
                    name: 'People & Culture',
                    url: '/company/people-culture-team'
                }
            },
            dataAnalyst: {
                jobActive: false,
                jobUrl: careerRoutes.dataAnalyst,
                teamUrl: '/company/data-analytics-team',
                teamName: 'Data Analytics Team',
                nextTeam: {
                    name: 'iOS',
                    url: '/company/ios-team'
                }
            },
            devops: {
                jobActive: false,
                jobUrl: careerRoutes.devops,
                teamUrl: '/company/devops-team',
                teamName: 'DevOps Team',
                nextTeam: {
                    name: 'Frontend',
                    url: '/company/frontend-team'
                }
            },
            pm: {
                jobActive: false,
                jobUrl: careerRoutes.productManager,
                teamUrl: '/company/product-management-team',
                teamName: 'Product Management Team',
                nextTeam: {
                    name: 'QA',
                    url: '/company/qa-team'
                }
            },
            peopleCulture: {
                jobActive: false,
                jobUrl: '',
                teamUrl: '/company/people-culture-team',
                teamName: 'People & Culture Team',
                nextTeam: {
                    name: 'Office',
                    url: '/company/office-team'
                }
            },
            office: {
                jobActive: false,
                jobUrl: '',
                teamUrl: '/company/office-team',
                teamName: 'Office Team',
                nextTeam: {
                    name: 'Design',
                    url: '/company/design-team'
                }
            }
        },

        menuIsSticky: false,
        companySubMenuLink: '',
        companySubMenuLinks: ['about', 'culture', 'teams'],

        cookieStorageKey: 'soCookiePreferences',
        cookiePopupVisible: false,
        cookiePreferences: null,
        companyProductShowcase: {
            2012: 'It all started with 3000 euros and an idea for the startup. That\'s how Score Alarm, the platform for real time sports data processing came to life.',
            2013: 'The app went through several redesigns, but it consistently provided users with real-time sports stats, alongside notifications for all major events and match highlights.',
            2014: 'We pivoted to B2B and started rebranding and adapting our solutions for lotteries and other entertainment companies.',
            2016: 'Large European bookmakers in Italy, Hungary, Germany etc. white labeled our app and placed it on their markets under their brand.',
            2017: 'Score Alarm company gets acquired and becomes a member of Superbet Group. In collaboration with other group members, we launched Superbet app that became rocket fuel for Superbet\'s market expansion.',
            2021: 'After rebranding, Score Alarm company becomes Superology.'
        },

        scrollToSettings: {
            duration: 800,
            lazy: false,
            easing: 'ease-out',
            offset: -100,
            force: true
        }
    },

    getters: {
        getScreenWidth: state => {
            return state.screenWidth
        },

        getSocialLinks: state => {
            return state.socialLinks
        },

        getGreenhouseJobs: state => {
            return state.greenhouseJobs
        },

        getCookiePopupVisible: state => {
            return state.cookiePopupVisible
        },

        getCookiePreferences: state => {
            return state.cookiePreferences
        },

        getCookieStorageKey: state => {
            return state.cookieStorageKey
        },

        getTeams: state => {
            return state.teams
        },

        getCompanySubMenuLink: state => {
            return state.companySubMenuLink
        },

        getCompanySubMenuLinks: state => {
            return state.companySubMenuLinks
        },

        getMenuIsSticky: state => {
            return state.menuIsSticky
        },

        getCompanyProductShowcase: state => {
            return state.companyProductShowcase
        },

        getScrollToSettings: state => {
            return state.scrollToSettings
        }
    },

    mutations: {
        setScreenWidth(state, payload) {
            state.screenWidth = payload
        },

        setCookiePreferences(state, payload) {

            // save the preferences object to localStorage
            localStorage.setItem(state.cookieStorageKey, JSON.stringify(payload))

            // save it into vuex
            state.cookiePreferences = payload
        },

        setCookiePopupVisible(state, payload) {
            state.cookiePopupVisible = payload
        },

        setCompanySubMenuLink(state, payload) {
            state.companySubMenuLink = payload
        },

        setMenuIsSticky(state, payload) {
            state.menuIsSticky = payload
        }
    },

    actions: {
        initCookiePreferences({getters}) {

            const cookiePreferences = getters.getCookiePreferences

            // check if there are any preferences from a previous visit
            if (cookiePreferences?.analytics) {
                initGoogleAnalytics()
                initGtag()
            }
        }
    }
})

export default store
