export default [
    {
        path: '/blog',
        name: 'Blog',
        component: () => import('@/views/blog/BlogArchive.vue')
    },
    {
        path: '/blog/how-we-use-data-to-advance-user-experience-with-clickhouse',
        name: 'How we use data to advance user experience with ClickHouse',
        component: () => import('@/views/blog/posts/AdvanceUserExperienceWithClickHouse.vue')
    },
    {
        path: '/blog/BEAM-me-up-scotty-a-short-talk-with-sasa-juric',
        name: 'BEAM me up Scotty - A short talk with Sasa Juric',
        component: () => import('@/views/blog/posts/BeamMeUpScottySasaJuric.vue')
    },
    {
        path: '/blog/fastly-pushing-content-closer-to-users',
        name: 'Fastly: Pushing content closer to users',
        component: () => import('@/views/blog/posts/FastlyPushingContentCloserToUsers.vue')
    },
    {
        path: '/blog/how-more-downtime-drives-creativity-and-innovation',
        name: 'How more downtime drives creativity and innovation',
        component: () => import('@/views/blog/posts/HowMoreDowntimeDrivesCreativityInnovation.vue')
    },
    {
        path: '/blog/you-are-not-the-boss-of-me-empowered-teams-and-where-to-find-them',
        name: 'You are not the boss of me',
        component: () => import('@/views/blog/posts/YouAreNotTheBossOfMe.vue')
    }
]
