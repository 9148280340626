<template>
  <div class="home animated fadeIn">
    <HeroHeader />
    <ProductShowcase />
    <Hiring />
  </div>

</template>

<script>

import HeroHeader from '@/components/home/HeroHeader'
import ProductShowcase from '@/components/home/ProductShowcase'
import Hiring from '@/components/home/Hiring'
import metadata from '@/util/metadata'
import GsapReveal from '@/mixins/GsapReveal'

export default {
  name: 'Home',

  metaInfo: {
    meta: metadata.home,
    title: 'Superology is now Happening!',
    link: [
      {rel: 'canonical', href: 'https://superology.com'}
    ]
  },

  mixins: [GsapReveal],

  components: {
    HeroHeader,
    ProductShowcase,
    Hiring
  }
}
</script>
