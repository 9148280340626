<template>
    <section class="header-menu" ref="headerMenu" :class="{sticky : isSticky}">
        <div class="container">
            <router-link to="/">
                <div class="logo-wrap">
                    <img class="so-logo" src="../../../assets/superology-logo.svg" alt="Superology">
                    <img class="so-slogan" src="../../../assets/superology-slogan.svg" alt="The Science of being super"
                         ref="slogan">
                </div>
            </router-link>

            <Component :is="menuComponent" />
        </div>

        <a href="https://happening.xyz" class="happening-cta">
            <img src="../../../assets/superology-happening.svg" alt="Superology is now Happening">
        </a>
    </section>
</template>

<script>

import DesktopMenu from '@/components/layout/header/DesktopMenu.vue'
import MobileMenu from '@/components/layout/header/MobileMenu.vue'
import ScreenWidth from '@/mixins/ScreenWidth.js'

import {gsap} from 'gsap'
import {ScrollTrigger} from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

const MOBILE_VIEWPORT_TRIGGER = 1024
const STICKY_HEADER_OFFSET = 70

export default {
    name: 'HeaderMenu',

    props: {},

    data: function () {
        return {
            isInvisible: false
        }
    },

    mounted() {
        this.initStickyMenu()
    },

    mixins: [ScreenWidth],

    components: {DesktopMenu, MobileMenu},

    computed: {
        menuComponent() {
            return this.screenWidth >= MOBILE_VIEWPORT_TRIGGER ? 'DesktopMenu' : 'MobileMenu'
        },

        isSticky() {
            return this.$store.getters.getMenuIsSticky
        }
    },

    methods: {
        initStickyMenu() {
            window.addEventListener('scroll', this.handleSticky)
        },

        handleSticky() {
            this.$store.commit('setMenuIsSticky', window.pageYOffset > STICKY_HEADER_OFFSET)
        }
    },
}
</script>
