export default {
  computed: {
    screenWidth() {
      return this.$store.getters.getScreenWidth
    },

    visibleHeaderComponent() {
      const breakpoint = {
        mobile: 640,
        tablet: 1024
      }

      return this.screenWidth < breakpoint.mobile ? 'JobHeaderMobile' : this.screenWidth < breakpoint.tablet ? 'JobHeaderTablet' : 'JobHeaderDesktop'
    }
  },
}