const OG_TYPE = 'og:type'
const OG_URL = 'og:url'
const OG_TITLE = 'og:title'
const OG_DESCRIPTION = 'og:description'
const REL_CANONICAL = 'canonical'
const DESCRIPTION = 'description'
const TWITTER_CARD = 'twitter:card'
const TWITTER_IMAGE = 'twitter:image'
const OG_IMAGE = 'og:image'

export {
    OG_TYPE, OG_URL, OG_TITLE, OG_DESCRIPTION, DESCRIPTION, REL_CANONICAL, TWITTER_IMAGE, TWITTER_CARD, OG_IMAGE
}
