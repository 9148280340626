import Careers from '@/views/Careers.vue'
import careerRoutes from '@/router/careerRoutes'

export default [
    {
        path: careerRoutes.main,
        name: 'Careers',
        component: Careers
    },
]
