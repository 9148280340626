<template>
    <section class="perks" :class="{connected: isConnected}">
        <div class="container">
            <div class="hero-bullets">
                <div class="bullet headline">
                    <h2 class="gs-animate fade enter">Why you'll love working here</h2>

                    <p class="sub-header gs-animate fade enter"><span
                        class="highlight-green">Unlimited everything</span>, as long as you get the job done</p>

                    <p class="gs-animate fade enter">At Superology we strive to create an environment where everyone can
                        deploy their talents. No strict policies, no questions asked. If we hired you, it means we trust
                        you. And if you’re doing great, we’ll make you feel greatly appreciated.</p>

                    <div class="superology-bullet-logo large-logo-padding">
                        <img src="../../assets/superology-hero-bullet-logo.svg" alt="Superology">
                    </div>
                </div>

<!--                <div class="bullet salary gs-animate fade enter">-->
<!--                    <div class="perk-bubble">-->
<!--                        <div class="icon-group">-->
<!--                            <div class="icon">-->
<!--                                <img src="../../assets/wallet.svg" alt="Wallet">-->
<!--                            </div>-->
<!--                            <h3>Starting net salary</h3>-->
<!--                        </div>-->
<!--                        <div class="description">-->
<!--                            Keep on growing and so will your salary, regularly and significantly.-->
<!--                        </div>-->

<!--                        <img class="salary-graph" :src="salaryImgSrc" alt="Salary graph">-->

<!--                        <div class="work-bonuses">-->
<!--                            <div class="description">-->
<!--                                + Well-deserved spot bonuses-->
<!--                            </div>-->
<!--                            <div class="description">-->
<!--                                + Annual performance bonus-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->

                <div class="perk-wrapper">

                    <div class="bullet gs-animate fade enter">
                        <div class="perk-bubble">
                            <div class="icon-group">
                                <div class="icon">
                                    <img src="../../assets/calendar.svg" alt="Calendar">
                                </div>
                                <h3>No hard deadlines</h3>
                            </div>
                            <div class="description">
                                We never burn the midnight oil. Everything can be postponed for a day or two.
                            </div>
                        </div>
                    </div>

                    <div class="bullet gs-animate fade enter">
                        <div class="perk-bubble">
                            <div class="icon-group">
                                <div class="icon">
                                    <img src="../../assets/clock.svg" alt="Clock">
                                </div>
                                <h3>Flexible work arrangements</h3>
                            </div>
                            <div class="description">
                                Find your most productive surroundings, whatever works for you.
                            </div>
                        </div>
                    </div>

                    <div class="bullet gs-animate fade enter">
                        <div class="perk-bubble">
                            <div class="icon-group">
                                <div class="icon">
                                    <img src="../../assets/vacation.svg" alt="Vacation">
                                </div>
                                <h3>Unlimited vacation</h3>
                            </div>
                            <div class="description">
                                We all work differently and rest differently. Take all the time you need to come back
                                fresh!
                            </div>
                        </div>
                    </div>

                    <div class="bullet gs-animate fade enter">
                        <div class="perk-bubble">
                            <div class="icon-group">
                                <div class="icon">
                                    <img src="../../assets/book.svg" alt="Book">
                                </div>
                                <h3>Professional growth</h3>
                            </div>
                            <div class="description">
                                Whether it’s conferences, office library or colleague coaching at Superology, we love to
                                see you grow.
                            </div>
                        </div>
                    </div>

                    <div class="bullet gs-animate fade enter">
                        <div class="perk-bubble">
                            <div class="icon-group">
                                <div class="icon">
                                    <img src="../../assets/monitor.svg" alt="Monitor">
                                </div>
                                <h3>Equipment of your choice</h3>
                            </div>

                            <div class="description">
                                When it comes to our people, we are big spenders. Pick all the top-notch tech stuff you
                                might need.
                            </div>
                        </div>
                    </div>

                    <div class="bullet gs-animate fade enter">
                        <div class="perk-bubble">
                            <div class="icon-group">
                                <div class="icon">
                                    <img src="../../assets/door.svg" alt="Door">
                                </div>
                                <h3>No open space</h3>
                            </div>
                            <div class="description">
                                All the peace and quiet you want, walls are the best. Doors too - not the band.
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </section>
</template>

<script>

import SalaryImgTiny from '@/assets/salary-graph-tiny.svg'
import SalaryImgSmall from '@/assets/salary-graph-small.svg'
import SalaryImgMedium from '@/assets/salary-graph-medium.svg'
import SalaryImgLarge from '@/assets/salary-graph-large.svg'
import SalaryImgXLarge from '@/assets/salary-graph-xlarge.svg'

const salaryImg = {
    tiny: SalaryImgTiny,
    small: SalaryImgSmall,
    medium: SalaryImgMedium,
    large: SalaryImgLarge,
    xlarge: SalaryImgXLarge
}

export default {
    name: 'Perks',

    props: {
        // displays the connected vertical line from the previous section
        isConnected: {
            default: false,
            type: Boolean
        }
    },

    data: function () {
        return {}
    },

    created() {

    },

    components: {},

    computed: {
        screenWidth() {
            return this.$store.getters.getScreenWidth
        },

        salaryImgSrc() {
            if (this.screenWidth < 480) return salaryImg.tiny
            if (this.screenWidth < 768) return salaryImg.small
            if (this.screenWidth < 1024) return salaryImg.medium
            if (this.screenWidth < 1280) return salaryImg.large
            return salaryImg.xlarge
        }
    },

    methods: {}
}


</script>
