<template>
  <section class="product-showcase company-showcase">
    <div class="container">
      <Component :is="showcaseComponent" />
    </div>
  </section>
</template>

<script>
import ShowcaseAnimationMobile from "./ShowcaseAnimationMobile"
import ShowcaseAnimationDesktop from "./ShowcaseAnimationDesktop"

const MOBILE_VIEWPORT_TRIGGER = 1024

export default {
  name: "ProductShowcase",

  components: {ShowcaseAnimationMobile, ShowcaseAnimationDesktop},

  computed: {
    showcaseComponent(){
      return this.$store.getters.getScreenWidth > MOBILE_VIEWPORT_TRIGGER ? 'ShowcaseAnimationDesktop' : 'ShowcaseAnimationMobile'
    }
  },

  methods: {}
}
</script>
