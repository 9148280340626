export default {
  created() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },

  computed: {
    screenWidth(){
      return this.$store.getters.getScreenWidth
    }
  },

  methods: {
    handleResize(){
      this.$store.commit('setScreenWidth', window.innerWidth)
    }
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  }
}