<template>
  <svg viewBox="0 0 31 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M23.2694 3.75H0.5C0.223858 3.75 0 3.97386 0 4.25V5.75C0 6.02614 0.223858 6.25 0.5 6.25H23.2694V9.08114C23.2694
    9.47735 23.7082 9.71616 24.0409 9.50098L30.3509 5.41984C30.6555 5.22282 30.6555 4.77718 30.3509 4.58016L24.0409
    0.499017C23.7082 0.283842 23.2694 0.522648 23.2694 0.918856V3.75Z" :fill="hoverColor"/>
  </svg>
</template>

<script>
export default {
  name: 'ApplyArrow',

  props: {
    hoverColor: {
      required: true,
      type: String
    }
  },

  data: function () {
    return {}
  },

  created() {

  },

  components: {},

  computed: {},

  methods: {}
}

</script>