<template>
  <section class="not-everything-is-perfect stripe-mask white">
    <div class="container">

      <img src="../../assets/exclamation-triangle.svg" alt="Triagle icon" class="triangle">

      <div class="heading gs-animate fade enter">
        <h3 class="">Not everything is perfect</h3>

        <p>As much as any company, we have some pain points that need polishing or reinventing. Since we’re in constant pursuit of problems and solutions for our end users, here’s what we’re trying to improve in our own backyard.</p>
      </div>

      <div class="monthly-snippet gs-animate fade enter">
        <div class="date">September 2021</div>

        <h3>Turning boom into a bloom</h3>

        <p>We’re growing and expanding, and it’s happening very fast! It sure boosts the optimism, but at the same time, it’s getting harder to keep everyone equally included and informed, especially the new folks. We’re looking for ways to build up transparency and internal communication according to the new numbers. It means reimagining team syncs and the onboarding process for new hires.</p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'NotEverythingIsPerfect',

  props: {},

  data: function () {
    return {}
  },

  created() {

  },

  components: {},

  computed: {},

  methods: {}
}


</script>