function initGtag(){
    let script = document.createElement('script')

    script.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=G-J9W1FYFQV9')
    script.async = true

    document.head.insertAdjacentElement('beforeend', script)

    const gtagCode = `window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'G-J9W1FYFQV9');`

    addScriptToDOM(gtagCode)
}

function initGoogleAnalytics(){
    const googleAnalyticsCode = `(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
        (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
        m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
      })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');`

    addScriptToDOM(googleAnalyticsCode)

    window['ga-disable-UA-69182172-2'] = false
    ga('create', 'UA-69182172-2', 'auto')
    ga('send', 'pageview')
}

function addScriptToDOM(scriptCode){
    let script = document.createElement('script')

    script.setAttribute('type', 'text/javascript')
    script.setAttribute('language', 'javascript')

    let inlineScript = document.createTextNode(scriptCode)

    script.appendChild(inlineScript)

    document.head.insertAdjacentElement('beforeend', script)
}

export {
    initGtag, initGoogleAnalytics
}
