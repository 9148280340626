<template>
    <div class="team role animated fadeIn">
        <div class="hero-header standard careers">
            <div class="container">
                <CompanySubmenu />
                <TeamHeader>
                    <template v-slot:teamTitle>
                        <h1><span class="highlight-green">Design</span><br />team</h1>
                    </template>

                    <template v-slot:subHeader>
                        Where pretty pixels meet<br /><span class="highlight-green">great user experience</span>
                    </template>

                    <template v-slot:jersey>
                        <img ref="jersey" src="../../assets/teams/jersey-team-design.svg" alt="Design jersey">
                    </template>
                </TeamHeader>
            </div>
        </div>

        <section class="about-team stripe-mask dark">
            <div class="container">
                <p class="team-description gs-animate fade enter">
                    We work on the product (Superbet app), from the very idea to the product release, from the big
                    picture to the details and micro-interactions. Recognizing users' needs and translating them into
                    tangible experiences is what we do best.<br /><br /> Research is an integral part of the design
                    process. We take our time to go deeper: gather insights, explore and validate different solutions,
                    create wireframes and prototypes. Only when completely happy, we design it in colours and show it to
                    the world.
                </p>
                <div class="team-details">
                    <div class="team-size gs-animate fade enter">
                        <p class="caption">Team size</p>
                        <p class="size highlight-green">14</p>
                    </div>
                    <div class="tech-stack gs-animate fade enter">
                        <p class="caption">Tech stack</p>
                        <div class="icons">
                            <img src="../../assets/teams/figma.svg" alt="Figma" title="Figma">
                            <img src="../../assets/teams/adobe.svg" alt="Adobe" title="Adobe">
                            <img src="../../assets/teams/miro.svg" alt="Miro" title="Miro">
                            <img src="../../assets/teams/user-testing.svg" alt="User Testing" title="User Testing">
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <CompanyCollage />

        <JoinTeamCTA v-if="team.jobActive" :team="team" />

        <NextSuperTeam :nextTeam="nextTeam" />
    </div>
</template>

<script>
import TeamHeader from '@/components/teams/TeamHeader'
import CompanyCollage from '@/components/teams/CompanyCollage'
import NextSuperTeam from '@/components/teams/NextSuperTeam'
import JoinTeamCTA from '@/components/teams/JoinTeamCTA'
import headerComponentToggleMixin from '@/views/jobs/headerComponentToggleMixin'
import GsapReveal from '@/mixins/GsapReveal'
import CompanySubmenu from '@/components/company/CompanySubmenu.vue'
import metadata from '@/util/companyMetadata'

export default {
    name: 'DesignTeam',

    mixins: [headerComponentToggleMixin, GsapReveal],

    metaInfo: {
        meta: metadata.designTeam,
        title: 'Design team at Superology',
        link: [
            {rel: 'canonical', href: 'https://superology.com/company/design-team'}
        ]
    },

    components: {
        TeamHeader,
        CompanyCollage,
        NextSuperTeam,
        JoinTeamCTA,
        CompanySubmenu
    },

    computed: {
        team() {
            return this.$store.getters.getTeams['productDesigner']
        },
        nextTeam() {
            return this.team.nextTeam
        },
    },

    methods: {}
}
</script>
