import { OG_TYPE, OG_URL, OG_TITLE, OG_DESCRIPTION, DESCRIPTION, TWITTER_CARD, TWITTER_IMAGE, OG_IMAGE } from './metaPropConstants.js'

const defaultMeta = {
    ogType: {
        property: OG_TYPE,
        content: 'article'
    },
    twitterCard: {
        name: TWITTER_CARD,
        content: 'summary_large_image'
    },
    ogImage: {
        property: OG_IMAGE,
        content: 'https://superology.com/static/ogimg.jpg'
    },
    twitterImage: {
        name: TWITTER_IMAGE,
        content: 'https://superology.com/static/ogimg.jpg'
    },
}

const description = {
    home: 'An experienced product design and development company. We create entertainment solutions for the sports betting industry.',
    legal: 'Read our full cookie policy and privacy statement.',
    privacyStatement: 'Read our full privacy statement.',
    cookiePolicy: 'Read our full cookie policy.',
    careers: 'You don’t have to be a superhuman to work at Superology. Check out our open positions and apply now!',
    productDesigner: 'We are looking for a talented product designer to join our team. Apply now!',
    productDesignerDesignSystems: 'We are looking for a talented product designer for our design system team. Apply now!',
    uxResearcher: 'We are looking for a talented UX researcher to join our team. Apply now!',
    dataAnalyst: 'We are looking for a talented Data Analyst to join our team. Apply now!',
    backendDeveloper: 'We are looking for a talented backend developer to join our team. Apply now!',
    designSystemSpecialist: 'We are looking for a talented Design system specialist to join our team. Apply now!',
    androidDeveloper: 'We are looking for a talented Android developer to join our team. Apply now!',
    iOsDeveloper: 'We are looking for a talented iOS developer to join our team. Apply now!',
    devops: 'We are looking for a talented DevOps to join our team. Apply now!',
    frontendWebDeveloper: 'We are looking for a talented Frontend Web developer to join our team. Apply now!',
    productManager: 'We are looking for a talented Product manager to join our team. Apply now!',
    qa: 'We are looking for a talented QA to join our team. Apply now!',
    kotlinMultiplatformDeveloper: 'We are looking for a talented Kotlin multiplatform developer to join our team. Apply now!',
    javascriptDeveloper: 'We are looking for a talented Javascript developer to join our team. Apply now!',
    openApplication: 'Our doors are always open to all highly motivated individuals. If you believe you can make an impact at Superology, we would love to meet you.',
    blog: 'This is our forum. A place where we can go deeper into our product, technologies, processes and methodologies, data and knowledge, and the culture that supports it.'
}

export default {

    home: [
        {
            property: OG_URL,
            content: 'https://superology.com/'
        },
        defaultMeta.ogType,
        {
            property: OG_TITLE,
            content: 'Superology is now Happening!'
        },
        {
            property: OG_DESCRIPTION,
            content: description.home
        },
        {
            name: DESCRIPTION,
            content: description.home
        },
        defaultMeta.ogImage,
        defaultMeta.twitterImage,
        defaultMeta.twitterCard
    ],

    legal: [
        {
            property: OG_URL,
            content: 'https://superology.com/legal'
        },
        defaultMeta.ogType,
        {
            property: OG_TITLE,
            content: 'Legal | Superology'
        },
        {
            property: OG_DESCRIPTION,
            content: description.legal
        },
        {
            name: DESCRIPTION,
            content: description.legal
        },
        defaultMeta.ogImage,
        defaultMeta.twitterImage,
        defaultMeta.twitterCard
    ],

    privacyStatement: [
        {
            property: OG_URL,
            content: 'https://superology.com/privacy-statement'
        },
        defaultMeta.ogType,
        {
            property: OG_TITLE,
            content: 'Privacy statement | Superology'
        },
        {
            property: OG_DESCRIPTION,
            content: description.privacyStatement
        },
        {
            name: DESCRIPTION,
            content: description.privacyStatement
        },
        defaultMeta.ogImage,
        defaultMeta.twitterImage,
        defaultMeta.twitterCard
    ],

    cookiePolicy: [
        {
            property: OG_URL,
            content: 'https://superology.com/cookie-policy'
        },
        defaultMeta.ogType,
        {
            property: OG_TITLE,
            content: 'Cookie policy | Superology'
        },
        {
            property: OG_DESCRIPTION,
            content: description.cookiePolicy
        },
        {
            name: DESCRIPTION,
            content: description.cookiePolicy
        },
        defaultMeta.ogImage,
        defaultMeta.twitterImage,
        defaultMeta.twitterCard
    ],

    careers: [
        {
            property: OG_URL,
            content: 'https://superology.com/careers'
        },
        defaultMeta.ogType,
        {
            property: OG_TITLE,
            content: 'Careers | Superology'
        },
        {
            property: OG_DESCRIPTION,
            content: description.careers
        },
        {
            name: DESCRIPTION,
            content: description.careers
        },
        {
            property: 'og:image',
            content: 'https://superology.com/static/ogimg-careers.jpg'
        },
        {
            name: 'twitter:image',
            content: 'https://superology.com/static/ogimg-careers.jpg'
        },
        defaultMeta.twitterCard
    ],

    productDesigner: [
        {
            property: OG_URL,
            content: 'https://superology.com/careers/product-designer'
        },
        defaultMeta.ogType,
        {
            property: OG_TITLE,
            content: 'Product Designer | Superology'
        },
        {
            property: OG_DESCRIPTION,
            content: description.productDesigner
        },
        {
            name: DESCRIPTION,
            content: description.productDesigner
        },
        defaultMeta.ogImage,
        defaultMeta.twitterImage,
        defaultMeta.twitterCard
    ],

    productDesignerDesignSystems: [
        {
            property: OG_URL,
            content: 'https://superology.com/careers/product-designer-design-systems'
        },
        defaultMeta.ogType,
        {
            property: OG_TITLE,
            content: 'Product designer - Design systems | Superology'
        },
        {
            property: OG_DESCRIPTION,
            content: description.productDesignerDesignSystems
        },
        {
            name: DESCRIPTION,
            content: description.productDesignerDesignSystems
        },
        defaultMeta.ogImage,
        defaultMeta.twitterImage,
        defaultMeta.twitterCard
    ],

    androidDeveloper: [
        {
            property: OG_URL,
            content: 'https://superology.com/careers/android-developer'
        },
        defaultMeta.ogType,
        {
            property: OG_TITLE,
            content: 'Android developer | Superology'
        },
        {
            property: OG_DESCRIPTION,
            content: description.androidDeveloper
        },
        {
            name: DESCRIPTION,
            content: description.androidDeveloper
        },
        defaultMeta.ogImage,
        defaultMeta.twitterImage,
        defaultMeta.twitterCard
    ],

    dataAnalyst: [
        {
            property: OG_URL,
            content: 'https://superology.com/careers/data-analyst'
        },
        defaultMeta.ogType,
        {
            property: OG_TITLE,
            content: 'Data Analyst | Superology'
        },
        {
            property: OG_DESCRIPTION,
            content: description.dataAnalyst
        },
        {
            name: DESCRIPTION,
            content: description.dataAnalyst
        },
        defaultMeta.ogImage,
        defaultMeta.twitterImage,
        defaultMeta.twitterCard
    ],

    uxResearcher: [
        {
            property: OG_URL,
            content: 'https://superology.com/careers/ux-researcher'
        },
        defaultMeta.ogType,
        {
            property: OG_TITLE,
            content: 'UX researcher | Superology'
        },
        {
            property: OG_DESCRIPTION,
            content: description.uxResearcher
        },
        {
            name: DESCRIPTION,
            content: description.uxResearcher
        },
        defaultMeta.ogImage,
        defaultMeta.twitterImage,
        defaultMeta.twitterCard
    ],

    openApplication: [
        {
            property: OG_URL,
            content: 'https://superology.com/careers/open-application'
        },
        defaultMeta.ogType,
        {
            property: OG_TITLE,
            content: 'Open application | Superology'
        },
        {
            property: OG_DESCRIPTION,
            content: description.openApplication
        },
        {
            name: DESCRIPTION,
            content: description.openApplication
        },
        defaultMeta.ogImage,
        defaultMeta.twitterImage,
        defaultMeta.twitterCard
    ],

    iOsDeveloper: [
        {
            property: OG_URL,
            content: 'https://superology.com/careers/ios-developer'
        },
        defaultMeta.ogType,
        {
            property: OG_TITLE,
            content: 'iOS developer | Superology'
        },
        {
            property: OG_DESCRIPTION,
            content: description.iOsDeveloper
        },
        {
            name: DESCRIPTION,
            content: description.iOsDeveloper
        },
        defaultMeta.ogImage,
        defaultMeta.twitterImage,
        defaultMeta.twitterCard
    ],

    backendDeveloper: [
        {
            property: OG_URL,
            content: 'https://superology.com/careers/backend-developer'
        },
        defaultMeta.ogType,
        {
            property: OG_TITLE,
            content: 'Backend developer | Superology'
        },
        {
            property: OG_DESCRIPTION,
            content: description.backendDeveloper
        },
        {
            name: DESCRIPTION,
            content: description.backendDeveloper
        },
        defaultMeta.ogImage,
        defaultMeta.twitterImage,
        defaultMeta.twitterCard
    ],

    frontendWebDeveloper: [
        {
            property: OG_URL,
            content: 'https://superology.com/careers/frontend-web-developer'
        },
        defaultMeta.ogType,
        {
            property: OG_TITLE,
            content: 'Frontend Web developer | Superology'
        },
        {
            property: OG_DESCRIPTION,
            content: description.frontendWebDeveloper
        },
        {
            name: DESCRIPTION,
            content: description.frontendWebDeveloper
        },
        defaultMeta.ogImage,
        defaultMeta.twitterImage,
        defaultMeta.twitterCard
    ],

    productManager: [
        {
            property: OG_URL,
            content: 'https://superology.com/careers/product-manager'
        },
        defaultMeta.ogType,
        {
            property: OG_TITLE,
            content: 'Product manager | Superology'
        },
        {
            property: OG_DESCRIPTION,
            content: description.productManager
        },
        {
            name: DESCRIPTION,
            content: description.productManager
        },
        defaultMeta.ogImage,
        defaultMeta.twitterImage,
        defaultMeta.twitterCard
    ],

    qa: [
        {
            property: OG_URL,
            content: 'https://superology.com/careers/qa-engineer'
        },
        defaultMeta.ogType,
        {
            property: OG_TITLE,
            content: 'QA engineer | Superology'
        },
        {
            property: OG_DESCRIPTION,
            content: description.qa
        },
        {
            name: DESCRIPTION,
            content: description.qa
        },
        defaultMeta.ogImage,
        defaultMeta.twitterImage,
        defaultMeta.twitterCard
    ],

    kotlinMultiplatformDeveloper: [
        {
            property: OG_URL,
            content: 'https://superology.com/careers/kotlin-multiplatform-developer'
        },
        defaultMeta.ogType,
        {
            property: OG_TITLE,
            content: 'Kotlin multiplatform developer | Superology'
        },
        {
            property: OG_DESCRIPTION,
            content: description.kotlinMultiplatformDeveloper
        },
        {
            name: DESCRIPTION,
            content: description.kotlinMultiplatformDeveloper
        },
        defaultMeta.ogImage,
        defaultMeta.twitterImage,
        defaultMeta.twitterCard
    ],

    javascriptDeveloper: [
        {
            property: OG_URL,
            content: 'https://superology.com/careers/javascript-developer'
        },
        defaultMeta.ogType,
        {
            property: OG_TITLE,
            content: 'Javascript developer | Superology'
        },
        {
            property: OG_DESCRIPTION,
            content: description.javascriptDeveloper
        },
        {
            name: DESCRIPTION,
            content: description.javascriptDeveloper
        },
        defaultMeta.ogImage,
        defaultMeta.twitterImage,
        defaultMeta.twitterCard
    ],

    devops: [
        {
            property: OG_URL,
            content: 'https://superology.com/careers/devops'
        },
        defaultMeta.ogType,
        {
            property: OG_TITLE,
            content: 'DevOps | Superology'
        },
        {
            property: OG_DESCRIPTION,
            content: description.devops
        },
        {
            name: DESCRIPTION,
            content: description.devops
        },
        defaultMeta.ogImage,
        defaultMeta.twitterImage,
        defaultMeta.twitterCard
    ],

    blog: [
        {
            property: OG_URL,
            content: 'https://superology.com/blog'
        },
        defaultMeta.ogType,
        {
            property: OG_TITLE,
            content: 'Blog | Superology'
        },
        {
            property: OG_DESCRIPTION,
            content: description.blog
        },
        {
            name: DESCRIPTION,
            content: description.blog
        },
        {
            property: 'og:image',
            content: 'https://superology.com/static/ogimg-blog.jpg'
        },
        {
            name: 'twitter:image',
            content: 'https://superology.com/static/ogimg-blog.jpg'
        },
        defaultMeta.twitterCard
    ]
}
