<template>
  <section class="role-cards" id="teams">
    <div class="container">
      <h2 class="gs-animate fade enter">
        <span class="highlight-green">Meet our teams</span>
      </h2>

      <div class="role-list">
        <TeamBubble v-for="(team, index) in teams" :team="team" :key="index"/>
      </div>
    </div>
  </section>
</template>

<script>
import TeamBubble from "./TeamBubble"

export default {
  name: "TeamsList",

  components: {TeamBubble},

  computed: {
    teams() {
      return this.$store.getters.getTeams
    },
  }
}
</script>
