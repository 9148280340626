<template>
    <div class="team role animated fadeIn">
        <div class="hero-header standard careers">
            <div class="container">
                <CompanySubmenu />
                <TeamHeader>
                    <template v-slot:teamTitle>
                        <h1><span class="highlight-green">iOS</span><br />team</h1>
                    </template>

                    <template v-slot:subHeader>
                        The dot on the i
                    </template>

                    <template v-slot:jersey>
                        <img ref="jersey" src="../../assets/teams/jersey-team-ios.svg" alt="iOS jersey">
                    </template>
                </TeamHeader>
            </div>
        </div>

        <section class="about-team stripe-mask dark">
            <div class="container">
                <p class="team-description gs-animate fade enter">
                    Our app processes a lot of rapidly changing real-time data. It connects millions of sports fans,
                    enabling them
                    to chat, share their bets, and be part of the action. This involves working with data from REST to
                    WebSocket,
                    combining it and presenting it to users in real-time. We are always refining and optimizing our
                    development
                    processes, using that knowledge in new features as well as refactoring old ones. We are always
                    learning and
                    exploring new things to make the best product there is.
                </p>
                <div class="team-details gs-animate fade enter">
                    <div class="team-size">
                        <p class="caption">Team size</p>
                        <p class="size highlight-green">13</p>
                    </div>
                    <div class="tech-stack gs-animate fade enter">
                        <p class="caption">Tech stack</p>
                        <div class="icons">
                            <img src="../../assets/teams/swift.svg" alt="Swift" title="Swift">
                            <img src="../../assets/teams/xcode.svg" alt="Xcode" title="Xcode">
                            <img src="../../assets/teams/ios.svg" alt="iOS" title="iOS">
                            <img src="../../assets/teams/rxJava.svg" alt="RxJava" title="RxJava">
                            <img src="../../assets/teams/fastlane.svg" alt="Fastlane" title="Fastlane">
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <CompanyCollage />

        <JoinTeamCTA v-if="team.jobActive" :team="team" />

        <NextSuperTeam :nextTeam="nextTeam" />
    </div>
</template>

<script>
import gsapReveal from '@/mixins/GsapReveal'

import TeamHeader from '@/components/teams/TeamHeader'
import CompanyCollage from '@/components/teams/CompanyCollage'
import NextSuperTeam from '@/components/teams/NextSuperTeam'
import JoinTeamCTA from '@/components/teams/JoinTeamCTA'
import CompanySubmenu from '@/components/company/CompanySubmenu.vue'
import metadata from '@/util/companyMetadata'

export default {
    name: 'DataAnalyticsTeam',

    mixins: [gsapReveal],

    metaInfo: {
        meta: metadata.iosTeam,
        title: 'iOS team at Superology',
        link: [
            {rel: 'canonical', href: 'https://superology.com/company/ios-team'}
        ]
    },

    components: {
        TeamHeader,
        CompanyCollage,
        NextSuperTeam,
        JoinTeamCTA,
        CompanySubmenu
    },

    computed: {
        team() {
            return this.$store.getters.getTeams['iOS']
        },
        nextTeam() {
            return this.team.nextTeam
        },
    },

    methods: {}
}
</script>
