<template>
    <section class="product-showcase" ref="phoneAnimation">
        <div class="container">
            <div class="showcase-heading">
                <div class="showcase-bullet">
                    <div class="ico">
                        <img src="../../assets/superbetapp-icon.svg" alt="Superbet app">
                    </div>
                    <h2 class="gs-animate fade enter">Superbet mobile app</h2>
                </div>
                <p class="gs-animate fade enter">
                    Whether it’s sports stats, live streams or social network - we create the perfect bet by adding what
                    matters.</p>

                <p class="app-available-countries gs-animate fade enter">
                    <span>currently available in</span>
                    <span class="available-country-flags">
                        PL <img src="../../assets/poland-flag.svg" alt="Poland">
                        RO <img src="../../assets/romanian-flag.svg" alt="Romania">
                    </span>

                </p>
            </div>

            <ProductAnimation />
        </div>
    </section>
</template>

<script>
import ProductAnimation from '@/components/home/ProductAnimation.vue'

export default {
    name: 'ProductShowcase',

    props: {},

    data: function () {
        return {}
    },

    mounted() {

    },

    components: {
        ProductAnimation
    },

    computed: {},

    methods: {},

    beforeDestroy() {
    }
}


</script>
