<template>
  <div class="legal animated fadeIn">
    <section class="hero-header standard">
      <div class="container">
        <h1><span class="highlight-green">Privacy</span> statement</h1>
      </div>
    </section>

    <section class="content legal stripe-mask dark">
      <div class="container">
        <div class="narrow-wrap">
          <p>The following Privacy Statement shall govern the lawful, fair and transparent processing of Personal Data
            of natural persons conducted by Superology in regard to natural persons applying for employment through a
            contact form (hereafter: the Candidate) on the Superology website (www.superology.com/careers)</p>

          <p>By reading this Privacy Statement, you hereby acknowledge and agree that the personal data you provide to
            us is supplied at your own free will and will be used in accordance with the purposes described in this
            document. If you do not understand this Privacy Statement, please do not share your personal data with
            us.</p>

          <p>We reserve the right to change and/or update our Privacy Statement at our discretion and at any time. You
            will be notified accordingly and in advance about these changes and or updates, which we kindly ask you to
            read carefully and make sure you understand and agree with.</p>

          <h2>1. General information</h2>

          <p>Superology d.o.o. (hereinafter Superology) is an R&D innovation hub that provides entertainment solutions
            for the market-leading sports betting operator and acts as a part of Superbet Group.</p>

          <p>Superbet Group1 and all affiliates is a betting & gambling organizer, trading under the brand name Superbet
            and part of the Superbet group of companies.</p>

          <p>Superology d.o.o. is a Controller of the personal data we process about you.</p>

          <p>The term “employee” refers to all employees, directors, officers and Board members of Superology. For the
            purposes of this privacy Statement, it also refers to other consultants and individual contractors engaged
            by Superology, even though they are not otherwise employees.</p>

          <p> The term <strong>“Candidate”</strong> is used in this Statement to refer to anyone who applies for a job
            role, or who otherwise seeks to work with or for us (whether on a permanent or non-permanent basis).</p>

          <h2>2. What personal information we collect?</h2>

          <p>As used in this Privacy Statement, personal data means any information that can be used to individually
            identify you, directly or indirectly, alone or along with other information, or contact you online or
            elsewhere.</p>

          <p>The purpose of processing your personal data is to carry out specific recruitment activities and to provide
            you with information on various employment opportunities within Superology.</p>

          <p>The legal basis for processing your personal data is the possibility of taking steps at your request prior
            to entering into a contract for a job within Superology and subsequently your consent in accordance with the
            requirements of GDPR.</p>

          <p>You have the right to withdraw your consent at any time, without prejudice to the lawfulness of the
            processing carried out on the basis of the consent before its withdrawal.</p>

          <p>The categories of personal data that can be processed are those provided by you in your CV and/or Cover
            Letter. Sometimes, you may be asked for other categories of data, based on which a possible employment
            decision can be made such as:</p>

          <ul>
            <li>First and last name, address</li>
            <li>email, skype ID, IP address, mobile number</li>
            <li>Job title, information related to current and previous labour relations</li>
            <li>Social media profile URL (LinkedIn, Facebook, GitHub, Stack Overflow, AngelList, Xing, Google Plus,
              Twitter)
            </li>
            <li>work portfolio</li>
            <li>Information related to education</li>
            <li>Internet browser information</li>
            <li>Cookies, which allow us to track how you use our websitesDuring the recruitment process, we generally do
              not
              collect or process any “Sensitive Personal Information”. Sensitive Personal Information includes the
              following: information that reveals your racial or ethnic origin, religious, political, or philosophical
              beliefs, or trade union membership; genetic data; biometric data for the purposes of unique
              identification;
              or information concerning your health, sex life, or sexual orientation
            </li>
          </ul>

          <p>At the same time, according to our internal policies and local legal provisions, we may collect information
            about you from open sources. We can also ask you for recommendations/references about your current or
            previous professional activities.</p>

          <h2>3. How we collect your personal information?</h2>

          <p>We solely collect your personal data with your informed knowledge and when you voluntarily submit it to
            us.</p>

          <p>After the Candidate has provided us with their Personal Data through our contact form, we immediately
            forward the data to our Data Processor, a company specialised in recruitment and Candidates tracking.</p>

          <p>All Personal Data processed by our Data Processor is done in accordance with the provisions of the GDPR and
            a binding Data Processing Agreement.</p>

          <p>NOTE: After forwarding your Personal data to our Data processor, Superology does not save or in any other
            way process your Personal data.

          <p>Although Superology may not be interested in hiring the Candidate within a reasonable time frame after
            receiving the application, our Data Processor may process your Personal Data for an extended period after
            application, should Superology decide to extend an offer to the Applicant due to changed circumstances
            within that time frame.</p>

          <p>If you are accepted for a role at Superology, the information collected during the recruiting process will
            be processed in accordance with applicable law, including any Employee Privacy Statement, a copy of which
            will be provided when you are on-boarded as an employee if applicable.</p>

          <p>Once collected, we shall at all times ensure that any processing of your personal data is carried out in
            compliance with applicable laws, with this Privacy Statement and for the purpose for which you submitted
            your data to us.</p>

          <h2>4. What we use your personal information for?</h2>

          <p>By law, we must have a lawful reason for processing your personal information. The data we collect about
            you will only be used for the purpose for which you submitted it to us (i.e. toenable us to ascertain
            whether we would be interested in making the Candidate a part of our team as an employee or through any
            other legally binding form of service provision), to perform our legal obligations and our legitimate
            interests in specific circumstances. Such purposes are made clear to you at the point of collection or in
            this Privacy Statement. Thus, we process your personal data for the following purposes:</p>

          <ul>
            <li><strong>Processing is necessary for the performance of the contract to which you are a party or in order
              to take action at your request prior to entering into a contract;</strong> (Information concerning your
              application and our assessment of it, your references, any checks we may make to verify information
              provided or background checks and any information connected with your right to work)
            </li>
            <li><strong>Based on your prior consent for different purposes</strong> (e.g., retention of CV information
              and related documents for potential job opportunities)
            </li>
            <li><strong>In our legitimate interests which we or a third party may have</strong>, except when such
              interests override by your interests or fundamental rights and freedom (e.g. such as general HR
              administration, maintaining a pool of Candidates to which an employment offer may be extended within a
              predetermined time frame, depending on relevant circumstances and employer needs; general business
              management and operations, disclosures for auditing and reporting purposes, internal investigations,
              management of network and information systems security and business operations. We may also process your
              personal data to investigate potential violations of law or breaches of our internal policies.);
            </li>
          </ul>

          <p>Also, we make sure that all our employees which have access to your personal data will process them only
            according their job responsibilities and in compliance with all laws, regulations or with your consent and
            these requirements are stated in their labour contract and undisclosed agreement they signed with
            Superology.</p>

          <h2>5. What happens if you do not provide or allow us to use your personal data? </h2>

          <p>You can decide at any time not to provide us with the requested personal data. However, if you refuse to
            share your personal data with us when requested, we may not be able to fulfil and undergo our recruitment
            procedures and potentially provide you with job opportunities.</p>


          <p>Your refusal to share your personal information with us in order to benefit from certain services may also
            limit the services and benefits we may offer you. For example, if you do not give us your consent to sign up
            to our sports and relaxing programs, you will not be able to receive such benefits. </p>

          <h2>6. Who do we share your personal data with?</h2>

          <p>We will never share your personal data with any third party who intends to use it for commercial purposes,
            unless we have expressly informed you and you have given us explicit permission to do so. In certain
            circumstances described below, we will share a minimum amount of personal data as follows:</p>

          <ul>
            <li><strong>Data processors authorized and contracted by us to process personal data such as our
              contractors</strong> (i.e.
              recruiting or executive search agencies involved in your recruiting; shared services and recruiting
              platform providers, IT developers and support providers and providers of hosting services in relation to
              our careers website; and third parties who provide support and advice including in relation to legal,
              financial / audit, management consultancy, insurance, health and safety, security and intel and
              whistleblowing / reporting issues;
            </li>
            <li><strong>Internally</strong> with managers, HR and recruitment teams, systems administrators, and other
              members of
              Superology’s group for our recruitment
            </li>
            <li><strong>Any competent law enforcement body</strong>, regulatory authority, government agency, court of
              law or other third
              party where we believe disclosure is necessary (i) as a matter of applicable law or regulation, (ii) to
              exercise, establish or defend our legal rights, or (iii) to protect your vital interests or those of any
              other person, if there is an appropriate legal basis for the processing of personal data
            </li>
            <li><strong>Based on consent</strong> in cases where your personal data is transferred to any other third
              party where you
              have consented or requested that we do so (advertising field or market research companies, for various
              polls, statistics, or to present to you some on-line commercials or to help us to evaluate some on-line
              services, viewing creatives or other contents
            </li>
            <li>Also, we can use or disclose information that we consider are suitable, to the extent that the records
              were quoted or related to ongoing or potential disputes, or to impose or protect the rights or safety of
              others.
            </li>
          </ul>

          <p>Usually, when we disclose this personal information to other companies and institutions, we are properly
            taking care to share only minimum amount of personal needed to fulfil their functions, making them aware
            that these data can be used only when services are performing in our behalf or to comply with legal
            requirements. If the case, we apply security measures to request our services’ suppliers to protect your
            personal data.</p>

          <p>In case we share your personal data outside the EU, Superology has in place contractual relationships with
            each such recipient that include standard contractual clauses intended to ensure an adequate level of
            protection and will always conduct rigorous due diligence to ensure there are no risks to such transfers</p>

          <h2>7. Where is your personal data stored?</h2>

          <p>A network of computers, cloud-based servers, and other infrastructure and information technology,
            including, but not limited to, third-party service providers support our operations. We and our third-party
            service providers store and process your Personal Data in the European Union.</p>

          <p>Our Data Processor may not transfer any Personal Data to third countries without written consent by the
            Data Controller.</p>
          <p>Each of our service providers who process and store your personal data have an obligation to keep it
            protected and secured, in accordance with applicable industry standards and whether less stringent legal
            provisions apply in their jurisdiction.</p>
          <p>Where such recipients of data are located in third countries for which the European Commission has not
            issued an adequacy decision, Superology has in place contractual relationships with each such recipient that
            include standard contractual clauses intended to ensure an adequate level of protection and will always
            conduct rigorous due diligence to ensure there are no risks to such transfers. A copy of the standard
            contractual clauses used by Superology can be found
            <a href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?uri=CELEX%3A32021D0914&locale=en"
               target="_blank">here</a>.</p>

          <h2>8. How long we keep your personal information for?</h2>

          <p>Your personal data will only be stored for a necessary and strict period of time in accordance with the
            purpose for which it is collected and applicable laws or regulatory requirements. Generally, this means we
            will keep your personal data until the end of your employment with us, plus a reasonable period of time
            after that where necessary to respond to any employment inquiries, deal with legal, tax, accounting or
            administrative matters, or to provide you with ongoing pensions or other benefits. Your personal data will
            be retained as documented in our corporate data retention schedule and applicable riders and
            supplements.</p>

          <p>The duration of the processing of your personal data will be done for a limited period, in accordance with
            the legal requirements, as follows:</p>

          <ul>
            <li>For recruitment purposes, your personal data will be stored during the recruitment process</li>
            <li>In case of a successful employment, your personal data will be stored in the HR personnel file
              permanently in accordance with Ordinance on the content and method of maintenance of Employees’ registry
              (Official Gazette No. 73/2017).
            </li>
            <li>In case of rejection to your application, your personal data will be stored for a period of 5 years if
              you provided us your consent for such storage
            </li>
            <li>for the duration of your consent (your explicit consent to the processing of personal data), i.e. until
              the moment of modification or withdrawal thereof,
            </li>
            <li>during the existence of our legitimate interests in preserving our rights, security and protection of
              property, as well as responding to any complaints, requests or other questions regarding our services,
            </li>
          </ul>

          <p>Upon expiration of the set retention periods, your personal data will be deleted or anonymised. If, at any time, you wish us to discontinue the use of your personal data, please contact us in the manner described in <strong>“How to access, modify, delete or object to the use of your personal data”</strong> section.</p>

          <p>If your requests to delete your personal data violate our legal or regulatory obligations, or the data related to the request for deletion are necessary to exercise or defense our legal claims, we may not be able to act upon your request and we will notify you of this decision. </p>

          <h2>9. How we keep your personal data secure?</h2>

          <p>We maintain technical, physical, and administrative security measures designed to provide reasonable protection for your Personal Data against loss, misuse, unauthorized access, disclosure, and alteration. The security measures include firewalls, data encryption, physical access controls to our data centres, and information access authorization controls.</p>

          <h2>10. Automated decision making and profiling</h2>

          <p>Our Data Processor is contractually obligated to provide effective technical and organisational measures to safeguard your Personal Data.</p>

          <p>Your personal data will not be profiled, and currently, we do not have automated decision making processes that affect you.</p>

          <h2>11. Your rights </h2>

          <p>At any given time, you will have the following rights regarding your personal data processed by us. Please note, other than your right to object to us using your information for direct marketing (and profiling for the purposes of direct marketing), your rights are not absolute in case of other applicable laws. </p>

          <p>(A) The right to information: you have the right to be provided with information on the identity of the personal data controller, on the reasons for processing you personal data and other relevant information necessary to ensure the correct and transparent processing of your personal data.</p>
          <p>(B) The right of access: you have the right to make a request for details of your personal information and a copy of that personal information.</p>
          <p>(C) The right of rectification: you have the right to have inaccurate information about you corrected or removed without undue delay.</p>
          <p>(D) The right of erasure ("right to be forgotten"): you have the right to have certain personal information about you deleted from our records.</p>
          <p>(E) The right to restriction of processing: you have the right to ask us to use your personal information for restricted purposes only.</p>
          <p>(F) The right to data portability: you have the right to ask us to transfer the personal information you have given us to you or to someone else in a format that can be read by computer.</p>
          <p>(G) The right to object: you have the right to object to us processing (including profiling) your personal information in cases where our processing is based on a task carried out in the public interest or where we have let you know it is necessary to process your information for our or a third party’s legitimate interests. You can object to us using your information for direct marketing and profiling purposes in relation to direct marketing.</p>
          <p>(H) The right in relation to automated decisions: you have the right not to have a decision which produces legal effects which concern you or which has a significant effect on you based only on automated processing, unless this is necessary for entering into a contract with you, it is authorised by law or you have given your permission for this.</p>

          <p>In order to exercise any of the above rights, you can send a signed and written request to our postal address or in writing to <strong>data.privacy@superbet.hr</strong> Your request will be reviewed and answered within 1 month of receipt, in accordance with the GDPR. If we receive a large number of requests, or particularly complex requests, the deadline can be extended by a maximum of another two months. In certain circumstances as prescribed by data protection law (i.e. excessive requests), we might refuse to act upon your request.</p>

          <p>In the event your personal data is processed based on your consent, you have the right to withdraw your consent at any time, without prejudice to the lawfulness of the processing carried out on the basis of the consent before its withdrawal.</p>

          <p>You also have the right to lodge a complaint with the National Authority for the Supervision of Personal Data Processing:</p>

          <p>Agencija za zaštitu osobnih podataka<br>
            Selska cesta 136, 10000 ZAGREB<br>
            azop@azop.hr</p>

          <h2>12. How you can access, modify, delete or object the use of your personal data</h2>

          <p>In order to exercise the Rights mentioned above, you can contact us by emailing data.privacy@superbet.hr At any time, you can refuse to share your personal data or object to our processing of your personal data. However, you should understand that if you choose this option in certain situations, we may not be able to fulfill our obligations to you, as stated in <strong>"What happens if you do not provide or allow us to use your personal data”</strong> section above.</p>

            <p>If you have consented to receive marketing communications from us and subsequently decide that you no longer wish to benefit from these, you can stop receiving such communications by unsubscribing any time. </p>

          <h2>13. How to contact us </h2>

          <p>If you have any questions, comments, complaints or suggestions relating to this Statement, or any other concerns about the way in which we process information about you, please contact our Data Protection Officer by emailing <strong>data.privacy@superbet.hr</strong></p>

        </div>
      </div>
    </section>
  </div>

</template>

<script>

import metadata from '@/util/metadata'

export default {
  name: 'PrivacyStatement',

  props: {},

  data: function () {
    return {}
  },

  metaInfo: {
    meta: metadata.privacyStatement,
    title: 'Privacy statement | Superology',
    link: [
      {rel: 'canonical', href: 'https://superology.com/privacy-statement'}
    ]
  },

  created() {
  },

  components: {},

  computed: {},

  methods: {}
}


</script>
