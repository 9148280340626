export default {
    computed: {
        activeBulletImage() {
            return require(`../assets/company/product-${this.activeBulletYear}.png`)
        },
        activeBulletImageSizes() {
            const largeSize = require(`../assets/company/2x-product-${this.activeBulletYear}.png`)
            const hugeSize = require(`../assets/company/3x-product-${this.activeBulletYear}.png`)

            return `${largeSize} 2x, ${hugeSize} 3x`
        }
    }
}
