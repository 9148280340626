<template>
    <div class="team role animated fadeIn">
        <div class="hero-header standard careers">
            <div class="container">
                <CompanySubmenu />
                <TeamHeader>
                    <template v-slot:teamTitle>
                        <h1><span class="highlight-green">Backend</span><br />team</h1>
                    </template>

                    <template v-slot:subHeader>
                        Serving the server
                    </template>

                    <template v-slot:jersey>
                        <img ref="jersey" src="../../assets/teams/jersey-team-backend.svg" alt="Backend jersey">
                    </template>
                </TeamHeader>
            </div>
        </div>

        <section class="about-team stripe-mask dark">
            <div class="container">
                <p class="team-description break-all gs-animate fade enter">
                    WWVwLCB0aGUgb2xkZXItdGhhbi1pbnRlcm5ldCBiYXNlNjQgdHJpY2suIENoZWFwLiBBbnl3YXlz
                    LCB3ZSBhcmUgYnVpbGRpbmcgYW5kIG1haW50YWluaW5nIHNjYWxhYmxlLCBtdWx0aS1jbGllbnQs
                    IG11bHRpLXByb3ZpZGVyLCBtdWx0aS1mZWVkIChhIGxvdCBvZiBtdWx0aeKAmXMpIHBsYXRmb3Jt
                    IHVzaW5nIEVsaXhpciBpbiBtaWNyb3NlcnZpY2VzIG9uIGs4cyB3aXRoIEthZmthIGFzIGEgZ2x1
                    ZSwgaG9zdGVkIG9uIEFXUyBhbmQgaGVhdmlseSBjYWNoZWQu
                </p>
                <div class="team-details">
                    <div class="team-size gs-animate fade enter">
                        <p class="caption">Team size</p>
                        <p class="size highlight-green">18</p>
                    </div>
                    <div class="tech-stack gs-animate fade enter">
                        <p class="caption">Tech stack</p>
                        <div class="icons">
                            <img src="../../assets/teams/elixir.svg" alt="Elixir" title="Elixir">
                            <img src="../../assets/teams/kafka.svg" alt="Kafka" title="Kafka">
                            <img src="../../assets/teams/kubernetes.svg" alt="Kubernetes" title="Kubernetes">
                            <img src="../../assets/teams/aws.svg" alt="AWS" title="AWS">
                            <img src="../../assets/teams/fastly.svg" alt="Fastly" title="Fastly">
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <CompanyCollage />

        <JoinTeamCTA v-if="team.jobActive" :team="team" />

        <NextSuperTeam :nextTeam="nextTeam" />
    </div>
</template>

<script>
import gsapReveal from '@/mixins/GsapReveal'

import TeamHeader from '@/components/teams/TeamHeader'
import CompanyCollage from '@/components/teams/CompanyCollage'
import NextSuperTeam from '@/components/teams/NextSuperTeam'
import JoinTeamCTA from '@/components/teams/JoinTeamCTA'
import CompanySubmenu from '@/components/company/CompanySubmenu.vue'
import metadata from '@/util/companyMetadata'

export default {
    name: 'BackendTeam',

    mixins: [gsapReveal],

    metaInfo: {
        meta: metadata.backendTeam,
        title: 'Backend team at Superology',
        link: [
            {rel: 'canonical', href: 'https://superology.com/company/backend-team'}
        ]
    },

    components: {
        TeamHeader,
        CompanyCollage,
        NextSuperTeam,
        JoinTeamCTA,
        CompanySubmenu
    },

    computed: {
        team() {
            return this.$store.getters.getTeams['backend']
        },
        nextTeam() {
            return this.team.nextTeam
        },
    },

    methods: {}
}
</script>
