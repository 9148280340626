<template>
  <div class="role-header-wrap teams-header">

    <div class="role-title">
      <div class="back-to-all-teams"
           @mouseover="backLinkHovered = true"
           @mouseleave="backLinkHovered = false">
        <router-link to="/company/#teams" class="arrow-link">
          <ApplyArrow :hoverColor="hoverColor"/>
          go back to all teams
        </router-link>
      </div>
      <slot name="teamTitle"></slot>
    </div>

    <div class="sub-header teams gs-animate fade enter">
      <slot name="subHeader"></slot>
    </div>

    <div class="jersey">
      <slot name="jersey"></slot>
    </div>

  </div>
</template>

<script>
import ApplyArrow from '@/components/careers/ApplyArrow.vue'

export default {
  name: "TeamHeader",

  data: function () {
    return {
      backLinkHovered: false
    }
  },

  components: {
    ApplyArrow
  },

  computed: {
    hoverColor() {
      const colors = {
        hover: '#D1CB19',
        default: '#7D899E'
      }

      return this.backLinkHovered ? colors.hover : colors.default
    }
  }
}
</script>
