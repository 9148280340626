<template>
  <section class="locations">
    <div class="container">
      <div class="locations-squiggly-wrap">
        <div class="squiggly-half">
          <div class="squiggly-line"></div>
          <div class="squiggly-right-curve"></div>
        </div>
      </div>
      <div class="hero-bullets">
        <div class="bullet headline">
          <h2 class="gs-animate fade enter">Superbet group locations</h2>

          <div class="superology-bullet-logo large-logo-padding">
            <img src="../../assets/company/location-superbet.svg" alt="Superology">
          </div>
        </div>

        <div class="location-wrapper">
          <div class="bullet gs-animate fade enter">
            <div class="location-bubble">
              <h3>Croatia</h3>
              <div class="location">
                Zagreb
              </div>
            </div>
          </div>
          <div class="bullet gs-animate fade enter">
            <div class="location-bubble">
              <h3>Romania</h3>
              <div class="location">
                Bucharest
              </div>
            </div>
          </div>
          <div class="bullet gs-animate fade enter">
            <div class="location-bubble">
              <h3>Serbia</h3>
              <div class="location">
                Belgrade
              </div>
            </div>
          </div>
          <div class="bullet gs-animate fade enter">
            <div class="location-bubble">
              <h3>Austria</h3>
              <div class="location">
                Vienna
              </div>
            </div>
          </div>
          <div class="bullet gs-animate fade enter">
            <div class="location-bubble">
              <h3>Poland</h3>
              <div class="location">
                  Warszawa & Katowice
              </div>
            </div>
          </div>
          <div class="bullet gs-animate fade enter">
            <div class="location-bubble">
              <h3>Slovakia</h3>
              <div class="location">
                Žilina
              </div>
            </div>
          </div>
          <div class="bullet gs-animate fade enter">
            <div class="location-bubble">
              <h3>Malta</h3>
              <div class="location">
                Silema
              </div>
            </div>
          </div>
          <div class="bullet gs-animate fade enter">
            <div class="location-bubble">
              <h3>UK</h3>
              <div class="location">
                London & Leeds
              </div>
            </div>
          </div>
          <div class="bullet gs-animate fade enter">
            <div class="location-bubble">
              <h3>Belgium</h3>
              <div class="location">
                Bruxelles
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "SuperbetLocations"
}
</script>
