<template>
  <div class="company animated fadeIn">
    <section class="hero-header standard" id="about">
      <div class="container">
        <CompanySubmenu/>
        <h1>
          When it comes to<br/> sports betting,<br/>
          <span class="highlight-green">a little bit of science</span><br/> goes a long way
        </h1>
      </div>
    </section>

    <section class="company-info stripe-mask dark">
      <div class="container">
        <div class="info gs-animate fade enter">
            <p>We realized this in 2012 when we launched a native mobile app called Score Alarm. Back then, betting enthusiasts searched for scores and stats on teletext and the web. Gathering all the relevant betting content in one place resulted in a great digital product in great demand. We made it fast, responsive, and informative. And from then on, we kept growing.</p>

            <p>What used to be a single mobile app is now the science behind Superbet, one of Europe’s biggest betting platforms.</p>
        </div>
      </div>
    </section>

    <ProductShowcase/>

    <SuperbetLocations/>

    <div class="terrace-bg"></div>

    <WhatMakesUsSuper/>

    <div class="table-soccer-bg"></div>

    <section class="company-employees" id="culture">
      <div class="container">
        <h2 class="gs-animate fade enter">
          <span class="highlight-green">Down-to-earth</span><br/> individuals developing<br/>
          out-of-this-world software
        </h2>
        <div class="sub-header gs-animate fade enter">
          We’ve gathered some really great brains that have stayed with us since the start of our startup. Now they make
          a great company.
        </div>

        <div class="employees-stats-wrap">
          <div class="employees-stats gs-animate fade enter">
            <div class="stats">114</div>
            <p class="description">
              talented professionals<br/>
              in 12 teams and 28 roles
            </p>
          </div>
          <div class="employees-stats gs-animate fade enter">
            <div class="stats">87%</div>
            <p class="description">
              of people work <br/> on the product
            </p>
          </div>
          <div class="employees-stats gs-animate fade enter">
            <div class="stats">~40%</div>
            <p class="description">
              of people have never<br/>
              placed a single bet
            </p>
          </div>
        </div>
      </div>
    </section>

    <TeamsList/>

    <section class="we-are-hiring super-job">
      <div class="container">
        <div class="hero-bullets no-border">
          <div class="bullet test">
            <h2 class="gs-animate fade enter">Dreaming of a super job?</h2>

            <div class="we-are-hiring-ico large-logo-padding">
              <img src="../assets/we-are-hiring-ico.svg" alt="We're hiring">
            </div>
          </div>
        </div>

        <div class="hero-bullets">
          <div class="bullet alt-header">
            <h2 class="gs-animate fade enter">
              Let's see how we fit into your<br/>career goals!
            </h2>
          </div>

          <div class="bullet">
            <router-link class="bullet-btn join-us" to="/careers">
              <ApplyArrow hoverColor="black"/>
              see open positions
            </router-link>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import GsapReveal from "../mixins/GsapReveal"
import ApplyArrow from "../components/careers/ApplyArrow"
import SuperbetLocations from "../components/company/SuperbetLocations"
import WhatMakesUsSuper from "../components/company/WhatMakesUsSuper"
import TeamsList from "../components/company/TeamsList"
import CompanySubmenu from "../components/company/CompanySubmenu.vue"
import ProductShowcase from "../components/company/ProductShowcase"
import metadata from "../util/companyMetadata"

import {gsap} from 'gsap'
import {ScrollTrigger} from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

export default {
  name: 'Company',

  data: function (){
    return {
      scrollTriggers: []
    }
  },

  mixins: [GsapReveal],

  metaInfo: {
    meta: metadata.company,
    title: 'Company | Superology',
    link: [
      {rel: 'canonical', href: 'https://superology.com/company'}
    ]
  },

  components: {WhatMakesUsSuper, TeamsList, ApplyArrow, SuperbetLocations, CompanySubmenu, ProductShowcase},

  mounted() {
    if (this.$route.hash) {
      this.scrollToActiveAnchor()
    }

    this.submenuAutoHighlight()
  },

  methods: {
    scrollToActiveAnchor() {
      this.$store.commit('setCompanySubMenuLink', this.$route.hash.replace('#', ''))
      const routeHash = this.$route.hash === '#about' ? '#superology' : this.$route.hash

      setTimeout(() => {
        this.$scrollTo(routeHash, 800, {offset: -60})
      }, 700)
    },

    submenuAutoHighlight() {

      setTimeout(() => {
        const aboutTrigger = new gsap.timeline({
          scrollTrigger: {
            trigger: '#superology',
            start: 'top 80px',
            end: 'bottom',
            onEnter: self => {
              this.setActiveSubmenu('about')
            },
          }
        })

        const cultureTrigger = new gsap.timeline({
          scrollTrigger: {
            trigger: '#culture',
            start: 'top 80px',
            end: 'bottom',
            onEnter: self => {
              this.setActiveSubmenu('culture')
            },
            onLeaveBack: self => {
              this.setActiveSubmenu('about')
            }
          }
        })

        const teamsTrigger = new gsap.timeline({
          scrollTrigger: {
            trigger: '#teams',
            start: 'top 80px',
            end: 'bottom',
            onEnter: self => {
              this.setActiveSubmenu('teams')
            },
            onLeaveBack: self => {
              this.setActiveSubmenu('culture')
            }
          }
        })

        this.scrollTriggers = [aboutTrigger, cultureTrigger, teamsTrigger]
      }, 20)
    },

    setActiveSubmenu(item) {
      this.$store.commit('setCompanySubMenuLink', item)
    }
  },

  beforeDestroy() {
    this.$store.commit('setCompanySubMenuLink', '')
    this.scrollTriggers.forEach(trigger => trigger.kill())
  }
}
</script>
