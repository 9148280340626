<template>
    <section class="footer">
        <div class="container">
            <div class="footer-logo">
                <img src="../../assets/footer-logo.svg" alt="Superology">
            </div>

            <div class="footer-links">
                <div class="footer-menu">
                    <div class="menu-item">
                        <router-link to="/" @click.native="handleRouteScroll">home</router-link>
                    </div>
                    <div class="menu-item">
                        <router-link to="/company" @click.native="handleRouteScroll">company</router-link>
                    </div>
                    <div class="menu-item">
                        <router-link to="/careers" @click.native="handleRouteScroll">careers</router-link>
                    </div>

                    <div class="menu-item">
                        <router-link to="/blog" @click.native="handleRouteScroll">blog</router-link>
                    </div>
                </div>

                <div class="footer-info">
                    <div class="info-box">
                        <h3>Follow us</h3>

                        <SocialLinks />
                    </div>

                    <div class="info-box">
                        <h3>Location</h3>

                        <div class="address">
                            <p class="location">Kuzminečka 10 <span class="neighbourhood">(Vrbani)</span>
                                <a href="https://goo.gl/maps/akahCct58nV7xCdB8" target="_blank" rel="noreferrer"><img
                                    class="location-ico" src="../../assets/ic_location.svg" alt="Location"></a>
                            </p>
                            <p>10000 Zagreb </p>
                        </div>
                    </div>

                    <div class="info-box">
                        <h3>Contact</h3>

                        <div class="contact">
                            <p><a href="mailto:info@superology.com">info@superology.com</a></p>
                            <p>+385 1 2090 383</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="copyright">© {{ currentYear }} Superology.
                <router-link to="/legal">Legal</router-link>
            </div>
        </div>
    </section>
</template>

<script>

import SocialLinks from '@/components/layout/SocialLinks.vue'

export default {
    name: 'Footer',

    props: {},

    data: function () {
        return {}
    },

    created() {

    },

    components: {SocialLinks},

    computed: {

        currentYear() {
            return new Date().getFullYear()
        },

        socialLinks() {
            return this.$store.getters.getSocialLinks
        }
    },

    methods: {
        handleRouteScroll() {
            document.getElementsByTagName('body')[0].scrollIntoView({behavior: 'smooth', block: 'start'})
        }
    }
}


</script>
