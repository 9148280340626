<template>
    <div class="team role animated fadeIn">
        <div class="hero-header standard careers">
            <div class="container">
                <CompanySubmenu />
                <TeamHeader>
                    <template v-slot:teamTitle>
                        <h1><span class="highlight-green">Frontend</span><br />team</h1>
                    </template>

                    <template v-slot:subHeader>
                        Web, untangled
                    </template>

                    <template v-slot:jersey>
                        <img ref="jersey" src="../../assets/teams/jersey-team-frontend.svg" alt="Frontend jersey">
                    </template>
                </TeamHeader>
            </div>
        </div>

        <section class="about-team stripe-mask dark">
            <div class="container">
                <p class="team-description gs-animate fade enter">
                    We are the middle-man that puts together all the pieces of the puzzle and forms a quality product
                    for the
                    end-user.<br /><br />

                    Since modern times require modern solutions, we got rid of all the Jabbascript legacy and fully
                    embraced the
                    awesomesauce that Vue.js provides. Our daily frontend routine revolves around integrating a creative
                    idea that
                    the design team has developed alongside the APIs managed by the backend team.
                </p>
                <div class="team-details gs-animate fade enter">
                    <div class="team-size">
                        <p class="caption">Team size</p>
                        <p class="size highlight-green">5</p>
                    </div>
                    <div class="tech-stack gs-animate fade enter">
                        <p class="caption">Tech stack</p>
                        <div class="icons">
                            <img src="../../assets/teams/javascript.svg" alt="JavaScript" title="JavaScript">
                            <img src="../../assets/teams/vue-js.svg" alt="Vue.js" title="Vue.js">
                            <img src="../../assets/teams/css3.svg" alt="CSS3" title="CSS3">
                            <img src="../../assets/teams/webstorm.svg" alt="WebStorm" title="WebStorm">
                            <img src="../../assets/teams/firebase.svg" alt="Firebase" title="Firebase">
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <CompanyCollage />

        <JoinTeamCTA v-if="team.jobActive" :team="team" />

        <NextSuperTeam :nextTeam="nextTeam" />
    </div>
</template>

<script>
import gsapReveal from '@/mixins/GsapReveal'

import TeamHeader from '@/components/teams/TeamHeader'
import CompanyCollage from '@/components/teams/CompanyCollage'
import NextSuperTeam from '@/components/teams/NextSuperTeam'
import JoinTeamCTA from '@/components/teams/JoinTeamCTA'
import CompanySubmenu from '@/components/company/CompanySubmenu.vue'
import metadata from '@/util/companyMetadata'

export default {
    name: 'FrontendTeam',

    mixins: [gsapReveal],

    metaInfo: {
        meta: metadata.frontendTeam,
        title: 'Frontend team at Superology',
        link: [
            {rel: 'canonical', href: 'https://superology.com/company/frontend-team'}
        ]
    },

    components: {
        TeamHeader,
        CompanyCollage,
        NextSuperTeam,
        JoinTeamCTA,
        CompanySubmenu
    },

    computed: {
        team() {
            return this.$store.getters.getTeams['web']
        },
        nextTeam() {
            return this.team.nextTeam
        },
    },

    methods: {}
}
</script>
