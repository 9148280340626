<template>
  <div class="omnom-nom" :class="{visible: isVisible}">
    <div class="biscuit-upper-wrap">
      <div class="biscuit-header">
        <div class="biscuit-title">But first, cookies</div>
      </div>
      <div class="biscuit-crunch"></div>
    </div>

    <div class="biscuit-lower-wrap">
      <div class="biscuit-info">This site needs cookies to function. We all do. Along with the necessary ones, we would
        like to use additional cookies to improve your experience.
      </div>

      <div class="biscuit-options">
        <div class="biscuit-item">
          <div class="biscuit-type">Necessary Cookies</div>
          <router-link to="/cookie-policy"><img class="biscuit-info-ico" src="../../assets/info.svg" alt="Info"></router-link>
        </div>
        <div class="biscuit-item">
          <div class="biscuit-type">Analytics Cookies</div>
          <div class="biscuit-prefs">
            <input class="toggle" type="checkbox" v-model="cookies.analytics"/>
            <router-link to="/cookie-policy"><img class="biscuit-info-ico" src="../../assets/info.svg" alt="Info"></router-link>
          </div>
        </div>
      </div>

      <div class="biscuit-disclaimer">
        Read more about it in our <router-link to="/cookie-policy">Cookie Policy</router-link>.
      </div>

      <div class="biscuit-footer">
        <div class="accept-selection" @click="saveCookiePreferences">accept selection</div>
        <div class="accept-all" @click="acceptAll">accept all</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CookiePopup',

  props: {},

  data: function () {
    return {
      cookies: {
        analytics: false
      },
      isVisible: false
    }
  },

  created() {
    if (this.cookiePreferences) {
      this.cookies = {...this.cookiePreferences}
    }
  },

  mounted(){
    setTimeout(() => {
      this.isVisible = true
    }, 1300)
  },

  components: {},

  computed: {
    cookiePreferences() {
      return this.$store.getters.getCookiePreferences
    },

    cookieStorageKey(){
      return this.$store.getters.getCookieStorageKey
    }
  },

  methods: {

    acceptAll() {
      Object.keys(this.cookies).forEach(key => this.cookies[key] = true)
      this.saveCookiePreferences()
    },

    saveCookiePreferences() {
      this.$store.commit('setCookiePreferences', {...this.cookies})
      this.$store.commit('setCookiePopupVisible', false)

      // TODO: Do GA stuff
      this.$store.dispatch('initCookiePreferences')
    }
  }
}


</script>
