<template>
  <section class="we-are-hiring">
    <div class="container">
<!--      <div class="hero-bullets padded-small"></div>-->
      <div class="hero-bullets no-border">
        <div class="bullet test">
          <h2 class="gs-animate fade enter">We're hiring!</h2>

          <div class="we-are-hiring-ico large-logo-padding">
            <img src="../../assets/we-are-hiring-ico.svg" alt="We're hiring">
          </div>
        </div>
      </div>

      <div class="hero-bullets">
        <div class="bullet alt-header">
          <h2 class="gs-animate fade enter"><span class="highlight-lilac">There are endless opportunities for improvement.</span> We need great talents <span class="highlight-lilac">to make it happen.</span></h2>
        </div>

        <div class="bullet">
          <router-link class="bullet-btn join-us" to="/careers">
            <ApplyArrow hoverColor="black" /> join us
          </router-link>
        </div>

      </div>
    </div>
  </section>
</template>

<script>
import ApplyArrow from '@/components/careers/ApplyArrow.vue'

export default {
  name: 'Hiring',

  props: {},

  data: function () {
    return {}
  },

  created() {

  },

  components: {ApplyArrow},

  computed: {},

  methods: {}
}


</script>
