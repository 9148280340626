<template>
    <div class="team role animated fadeIn">
        <div class="hero-header standard careers">
            <div class="container">
                <CompanySubmenu />
                <TeamHeader>
                    <template v-slot:teamTitle>
                        <h1><span class="highlight-green">Product Management</span><br />team</h1>
                    </template>

                    <template v-slot:subHeader>
                        Product is king
                    </template>

                    <template v-slot:jersey>
                        <img ref="jersey" src="../../assets/teams/jersey-team-product-management.svg" alt="PM jersey">
                    </template>
                </TeamHeader>
            </div>
        </div>

        <section class="about-team stripe-mask dark">
            <div class="container">
                <p class="team-description gs-animate fade enter">
                    We identify the users’ needs and business objectives, and empower our product teams to meet these
                    needs and
                    objectives through the product. Product managers contribute to the product team with a deep
                    understanding of
                    our users, data on users’ engagement with the product, as well as industry knowledge. We make sure
                    that each
                    team builds a feasible, valuable, and usable product, keeping UX, Tech, and Business in mind.
                </p>
                <div class="team-details gs-animate fade enter">
                    <div class="team-size">
                        <p class="caption">Team size</p>
                        <p class="size highlight-green">7</p>
                    </div>
                    <div class="tech-stack gs-animate fade enter">
                        <p class="caption">Tech stack</p>
                        <div class="icons">
                            <img src="../../assets/teams/notion.svg" alt="Notion" title="Notion">
                            <img src="../../assets/teams/miro.svg" alt="Miro" title="Miro">
                            <img src="../../assets/teams/figma.svg" alt="Figma" title="Figma">
                            <img src="../../assets/teams/jira.svg" alt="Jira" title="Jira">
                            <img src="../../assets/teams/user-testing.svg" alt="UserTesting" title="UserTesting">
                            <img src="../../assets/teams/tableau.svg" alt="Tableau" title="Tableau">
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <CompanyCollage />

        <JoinTeamCTA v-if="team.jobActive" :team="team" />

        <NextSuperTeam :nextTeam="nextTeam" />
    </div>
</template>

<script>
import gsapReveal from '@/mixins/GsapReveal'

import TeamHeader from '@/components/teams/TeamHeader'
import CompanyCollage from '@/components/teams/CompanyCollage'
import NextSuperTeam from '@/components/teams/NextSuperTeam'
import JoinTeamCTA from '@/components/teams/JoinTeamCTA'
import CompanySubmenu from '@/components/company/CompanySubmenu.vue'
import metadata from '@/util/companyMetadata'

export default {
    name: 'ProductManagementTeam',

    mixins: [gsapReveal],

    metaInfo: {
        meta: metadata.peopleAndCultureTeam,
        title: 'Product Management team at Superology',
        link: [
            {rel: 'canonical', href: 'https://superology.com/company/product-management-team'}
        ]
    },

    components: {
        TeamHeader,
        CompanyCollage,
        NextSuperTeam,
        JoinTeamCTA,
        CompanySubmenu
    },

    computed: {
        team() {
            return this.$store.getters.getTeams['pm']
        },
        nextTeam() {
            return this.team.nextTeam
        },
    },

    methods: {}
}
</script>
