<template>
  <section class="join-team-cta">
    <div class="container">
      <div class="hero-bullets">

        <div class="bullet headline">
          <h2 class="gs-animate fade enter">Want to join this team?</h2>

          <div class="superology-bullet-logo large-logo-padding">
            <img src="../../assets/we-are-hiring-ico.svg" alt="Superology">
          </div>
        </div>
        <div class="bullet apply-now-bullet-btn">
          <router-link class="bullet-btn join-us" :to="team.jobUrl">
            <ApplyArrow hoverColor="black"/>
            apply now
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ApplyArrow from "../careers/ApplyArrow"

export default {
  name: "JoinTeamCTA",
  props: {
    team: {
      type: Object,
      required: true
    }
  },

  components: {
    ApplyArrow
  },

  computed: {
  },
}
</script>
