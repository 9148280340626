<template>
    <div class="team role animated fadeIn">
        <div class="hero-header standard careers">
            <div class="container">
                <CompanySubmenu />
                <TeamHeader>
                    <template v-slot:teamTitle>
                        <h1><span class="highlight-green">Office</span><br />team</h1>
                    </template>

                    <template v-slot:subHeader>
                        Our little corner of the world
                    </template>

                    <template v-slot:jersey>
                        <img ref="jersey" src="../../assets/teams/jersey-team-office.svg" alt="QA jersey">
                    </template>
                </TeamHeader>
            </div>
        </div>

        <section class="about-team stripe-mask dark">
            <div class="container">
                <p class="team-description gs-animate fade enter">
                    You can’t make a product great without a roof over your head. We’re in charge of every little
                    invisible bit
                    and piece that make our workplace a great place to think, do, innovate and hang out at. Whether it’s
                    something
                    small or something big, we always have a solution that makes our whole mechanism tick.
                </p>
                <div class="team-details gs-animate fade enter">
                    <div class="team-size">
                        <p class="caption">Team size</p>
                        <p class="size highlight-green">7</p>
                    </div>
                    <div class="tech-stack gs-animate fade enter">
                        <p class="caption">Tech stack</p>
                        <div class="icons">
                            <img src="../../assets/teams/md-365.svg" alt="MD365" title="MD365">
                            <img src="../../assets/teams/i-term.svg" alt="iTerm" title="iTerm">
                            <img src="../../assets/teams/google-sheets.svg" alt="Google Sheets" title="Google Sheets">
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <CompanyCollage />

        <JoinTeamCTA v-if="team.jobActive" :team="team" />

        <NextSuperTeam :nextTeam="nextTeam" />
    </div>
</template>

<script>
import gsapReveal from '@/mixins/GsapReveal'

import TeamHeader from '@/components/teams/TeamHeader'
import CompanyCollage from '@/components/teams/CompanyCollage'
import NextSuperTeam from '@/components/teams/NextSuperTeam'
import JoinTeamCTA from '@/components/teams/JoinTeamCTA'
import CompanySubmenu from '@/components/company/CompanySubmenu.vue'
import metadata from '@/util/companyMetadata'

export default {
    name: 'OfficeTeam',

    mixins: [gsapReveal],

    metaInfo: {
        meta: metadata.officeTeam,
        title: 'Office team at Superology',
        link: [
            {rel: 'canonical', href: 'https://superology.com/company/office-team'}
        ]
    },

    components: {
        TeamHeader,
        CompanyCollage,
        NextSuperTeam,
        JoinTeamCTA,
        CompanySubmenu
    },

    computed: {
        team() {
            return this.$store.getters.getTeams['office']
        },
        nextTeam() {
            return this.team.nextTeam
        },
    },

    methods: {}
}
</script>
