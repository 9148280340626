<template>
  <section class="company-collage">
    <div class="images-wrap">
      <div class="collage-image-1">
        <img src="../../assets/teams/collage-image-1.jpg" alt="Collage-image-1">
      </div>
      <div class="collage-image-2">
        <img src="../../assets/teams/collage-image-2.jpg" alt="Collage-image-2">
      </div>
      <div class="collage-image-3">
        <img src="../../assets/teams/collage-image-3.jpg" alt="Collage-image-3">
      </div>
      <div class="collage-image-4">
        <img src="../../assets/teams/collage-image-4.jpg" alt="Collage-image-4">
      </div>
      <div class="collage-image-5">
        <img src="../../assets/teams/collage-image-5.jpg" alt="Collage-image-5">
      </div>
      <div class="collage-image-6">
        <img src="../../assets/teams/collage-image-6.jpg" alt="Collage-image-6">
      </div>
      <div class="collage-image-7">
        <img src="../../assets/teams/collage-image-7.jpg" alt="Collage-image-7">
      </div>
      <div class="collage-image-8">
        <img src="../../assets/teams/collage-image-8.jpg" alt="Collage-image-8">
      </div>
      <div class="collage-image-9">
        <img src="../../assets/teams/collage-image-9.jpg" alt="Collage-image-9">
      </div>
      <div class="collage-image-10">
        <img src="../../assets/teams/collage-image-10.jpg" alt="Collage-image-10">
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "CompanyCollage",

  computed: {},
}
</script>
