<template>
  <div class="careers animated fadeIn">
    <section class="hero-header standard careers">
      <div class="container">
        <h1>Stay true to yourself, while <span class="highlight-green">delivering to millions</span></h1>

        <div class="sub-header gs-animate fade enter">It’s exciting to work in a <span class="highlight-green">company that is changing the whole experience</span> of a certain human activity. Sports betting has never been more engaging, more informed, more fun.</div>
      </div>
    </section>

    <section class="careers-cta stripe-mask dark">
      <div class="container">
        <div class="cta gs-animate fade enter">If you want to be a part of this success story and to work in an open, honest and flexible work environment,
          <a href="#" class="highlight-green" v-scroll-to="anchorSettings">join us!</a>
        </div>
      </div>
    </section>

    <Perks />

    <OpenPositions />

    <div class="coachable-bg"></div>

    <WhatMakesUsSuper />

<!--    <NotEverythingIsPerfect />-->
  </div>
</template>

<script>

import OpenPositions from '@/components/careers/OpenPositions'
import Perks from '@/components/careers/Perks'
import WhatMakesUsSuper from '@/components/careers/WhatMakesUsSuper'
import NotEverythingIsPerfect from '@/components/careers/NotEverythingIsPerfect'
import metadata from '@/util/metadata'
import GsapReveal from '@/mixins/GsapReveal'

export default {
  name: 'Careers',

  props: {},

  data: function () {
    return {}
  },

  metaInfo: {
    meta: metadata.careers,
    title: 'Careers | Superology',
    link: [
      {rel: 'canonical', href: 'https://superology.com/careers'}
    ]
  },

  mixins: [GsapReveal],

  components: {OpenPositions, Perks, WhatMakesUsSuper, NotEverythingIsPerfect},

  computed: {

    scrollToSettings() {
      return this.$store.getters.getScrollToSettings;
    },

    anchorSettings() {
      return {
        el: '#open-positions',
        ...this.scrollToSettings
      }
    }
  },

  methods: {

  }
}


</script>
