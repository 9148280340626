<template>
  <div class="showcase-view-mobile">
    <div class="showcase-heading">
      <div class="showcase-bullet">
        <div class="ico">
          <img src="../../assets/company/icon-working.svg" alt="Product timeline">
        </div>
        <h2>
          How our product evolved through the years
        </h2>
      </div>
    </div>

    <swiper :options="swiperOption">
      <ShowcaseSwiper
          v-for="(description, year) in companyProductShowcase"
          :description="description"
          :activeBulletYear="year"
          :key="year"
      />
      <div class="bullets-wrap-mobile" slot="pagination"></div>
    </swiper>
  </div>
</template>

<script>
import ShowcaseSwiper from "./ShowcaseSwiper"
import {Swiper} from "vue-awesome-swiper"

const PRODUCT_YEARS = ['2012', '2013', '2014', '2016', '2017', '2021']

export default {
  name: "ShowcaseAnimationMobile",

  data: function () {
    return {
      swiperOption: {
        direction: 'horizontal',
        loop: true,
        autoplay: {
          delay: 15000
        },
        speed: 800,
        pagination: {
          el: '.bullets-wrap-mobile',
          clickable: true,
          renderBullet(index, className) {
            return `<div class="bullet-point ${className}"><div class="bullet"></div><h3 class="year">${PRODUCT_YEARS[index]}</h3></div>`
          }
        }
      }
    }
  },

  components: {Swiper, ShowcaseSwiper},

  computed: {
    companyProductShowcase() {
      return this.$store.getters.getCompanyProductShowcase
    },
  }
}
</script>
