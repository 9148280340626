import {gsap} from 'gsap'

import params from '@/components/home/showcase/animationParams'

export default {
  data: function(){
    return {
      animation: null
    }
  },

  mounted() {
    this.animate()
    if(this.timelinePosition){
      this.animation.progress(this.timelinePosition)
    }
  },

  methods: {
    animate() {

      const componentInstance = this
      // create and store the animation loop
      this.animation = new gsap.timeline({repeat:-1}).eventCallback('onUpdate', function(){
        componentInstance.$emit('setTimelinePosition', parseFloat(this.progress().toFixed(2)))
      })

      // hide all scenes
      this.animation.set(this.$refs.showcaseStats, {display: 'none'})
      this.animation.set(this.$refs.showcaseLive, {display: 'none'})
      this.animation.set(this.$refs.showcaseSocial, {display: 'none'})
      this.animation.set(this.$refs.showcaseStream, {display: 'none'})

      // stats enter
      this.animation.to(this.$refs.showcaseStats, {display: 'block'})
      componentInstance.$emit('stats')
      this.animation.to({}, {duration: 1})
      this.animation.fromTo(this.$refs.statsBubbletext, params.enterUp.from, params.enterUp.to)
      this.animation.fromTo(this.$refs.statsHerotext, params.enterUp.from, params.enterUp.to)
      this.animation.to({}, {duration: params.pauseTime})

      // stats leave
      this.animation.to(this.$refs.statsBubbletext, {opacity: 0, duration: params.fadeoutTime})
      this.animation.to(this.$refs.statsHerotext, {opacity: 0, duration: params.fadeoutTime}, '-=0.4')
      this.animation.to({}, {duration: 1})
      this.animation.set(this.$refs.showcaseStats, {display: 'none'})

      // live enter
      this.animation.set(this.$refs.showcaseLive, {display: 'block'})
      componentInstance.$emit('live')
      this.animation.to({}, {duration: 2})
      this.animation.fromTo(this.$refs.liveBubbletext, params.enterUp.from, params.enterUp.to)
      this.animation.fromTo(this.$refs.liveHerotext, params.enterUp.from, params.enterUp.to)
      this.animation.to({}, {duration: params.pauseTime})

      // live leave
      this.animation.to(this.$refs.liveBubbletext, {opacity: 0, duration: params.fadeoutTime})
      this.animation.to(this.$refs.liveHerotext, {opacity: 0, duration: params.fadeoutTime}, '-=0.4')
      this.animation.to({}, {duration: 1})
      this.animation.set(this.$refs.showcaseLive, {display: 'none'})

      // social enter
      this.animation.set(this.$refs.showcaseSocial, {display: 'block'})
      componentInstance.$emit('social')
      this.animation.to({}, {duration: 2})
      this.animation.fromTo(this.$refs.socialBubbletext, params.enterUp.from, params.enterUp.to)
      this.animation.fromTo(this.$refs.socialHerotext, params.enterUp.from, params.enterUp.to)
      this.animation.to({}, {duration: params.pauseTime})

      // social leave
      this.animation.to(this.$refs.socialBubbletext, {opacity: 0, duration: params.fadeoutTime})
      this.animation.to(this.$refs.socialHerotext, {opacity: 0, duration: params.fadeoutTime}, '-=0.4')
      this.animation.to({}, {duration: 1})
      this.animation.set(this.$refs.showcaseSocial, {display: 'none'})

      // stream enter
      this.animation.set(this.$refs.showcaseStream, {display: 'block'})
      componentInstance.$emit('stream')
      this.animation.to({}, {duration: 2})
      this.animation.fromTo(this.$refs.streamBubbletext, params.enterUp.from, params.enterUp.to)
      this.animation.fromTo(this.$refs.streamHerotext, params.enterUp.from, params.enterUp.to)
      this.animation.to({}, {duration: params.pauseTime})

      // stream leave
      this.animation.to(this.$refs.streamBubbletext, {opacity: 0, duration: params.fadeoutTime})
      this.animation.to(this.$refs.streamHerotext, {opacity: 0, duration: params.fadeoutTime}, '-=0.4')
      this.animation.to({}, {duration: 1})
    },

    pauseAnimation(){
      if(this.animation){
        this.animation.pause()
      }
    },

    resumeAnimation(){
      if(this.animation){
        this.animation.resume()
      }
    },

    setShowcaseSlide(timelinePosition){
      this.animation.progress(timelinePosition)
    }
  },

  beforeDestroy() {
    if(this.animation){
      this.animation.kill()
    }
  }
}
