<template>
    <div class="team role animated fadeIn">
        <div class="hero-header standard careers">
            <div class="container">
                <CompanySubmenu />
                <TeamHeader>
                    <template v-slot:teamTitle>
                        <h1><span class="highlight-green">DevOps</span><br />team</h1>
                    </template>

                    <template v-slot:subHeader>
                        Ops, we did it again
                    </template>

                    <template v-slot:jersey>
                        <img ref="jersey" src="../../assets/teams/jersey-team-devops.svg" alt="DevOps jersey">
                    </template>
                </TeamHeader>
            </div>
        </div>

        <section class="about-team stripe-mask dark">
            <div class="container">
                <p class="team-description devops-data gs-animate fade enter">
                    apiVersion: superology/latest<br />
                    kind: Team<br />
                    metadata:<br />
                    &ensp;name: DevOps<br />
                    &ensp;annotations:<br />
                    &emsp;kubernetes: true<br />
                    &emsp;IaC: true<br />
                    &emsp;AWS: true<br />
                </p>
                <p class="team-description gs-animate fade enter">
                    &ensp;description: Helping developers to create an environment where it is easy to improve and
                    deliver the product
                    fast. Making mistakes is human and that is why we automate and monitor everything. To deploy it on
                    staging,
                    all you need to do is push code to repo, and then you'll be one step from production.
                </p>
                <div class="team-details gs-animate fade enter">
                    <div class="team-size">
                        <p class="caption">Team size</p>
                        <p class="size highlight-green">5</p>
                    </div>
                    <div class="tech-stack gs-animate fade enter">
                        <p class="caption">Tech stack</p>
                        <div class="icons">
                            <img src="../../assets/teams/kubernetes.svg" alt="Kubernetes" title="Kubernetes">
                            <img src="../../assets/teams/argo.svg" alt="Argo" title="Argo">
                            <img src="../../assets/teams/prometheus.svg" alt="Prometheus" title="Prometheus">
                            <img src="../../assets/teams/terraform.svg" alt="Terraform" title="Terraform">
                            <img src="../../assets/teams/aws.svg" alt="AWS" title="AWS">
                            <img src="../../assets/teams/terminal.svg" alt="Terminal" title="Terminal">
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <CompanyCollage />

        <JoinTeamCTA v-if="team.jobActive" :team="team" />

        <NextSuperTeam :nextTeam="nextTeam" />
    </div>
</template>

<script>
import gsapReveal from '@/mixins/GsapReveal'

import TeamHeader from '@/components/teams/TeamHeader'
import CompanyCollage from '@/components/teams/CompanyCollage'
import NextSuperTeam from '@/components/teams/NextSuperTeam'
import JoinTeamCTA from '@/components/teams/JoinTeamCTA'
import CompanySubmenu from '@/components/company/CompanySubmenu.vue'
import metadata from '@/util/companyMetadata'

export default {
    name: 'DevOpsTeam',

    mixins: [gsapReveal],

    metaInfo: {
        meta: metadata.devOpsTeam,
        title: 'DevOps team at Superology',
        link: [
            {rel: 'canonical', href: 'https://superology.com/company/devops-team'}
        ]
    },

    components: {
        TeamHeader,
        CompanyCollage,
        NextSuperTeam,
        JoinTeamCTA,
        CompanySubmenu
    },

    computed: {
        team() {
            return this.$store.getters.getTeams['devops']
        },
        nextTeam() {
            return this.team.nextTeam
        },
    },

    methods: {}
}
</script>
