<template>
    <div class="job-fair-contest animated fadeIn">
        <section class="hero-header standard">
            <div class="container">
                <h1>Job Fair<br><span class="highlight-green">Privacy Notice</span></h1>
            </div>
        </section>

        <section class="content legal stripe-mask dark">
            <div class="container">
                <div class="narrow-wrap">
                    <p>We want you to understand how we protect your privacy when we obtain personal data from you, how
                        we use that information, what your rights are in this respect and how we safeguard that.</p>

                    <p>By reading this Privacy Notice, you hereby acknowledge and agree that the personal data you
                        provide to us is supplied at your own free will and will be used in accordance with the purposes
                        described in this Privacy Notice. If you do not understand this Privacy Notice, please do not
                        register on our website and do not share your personal data with us.</p>

                    <p>We reserve the right to change and/or update our Privacy Notice at our discretion and at any
                        time. You will be notified accordingly and in advance about these changes and or updates, which
                        we kindly ask you to read carefully and make sure you understand and agree with.</p>

                    <h2>1. General information</h2>

                    <p>SUPEROLOGY d.o.o., with headquarter in Zagreb, Kuzminečka cesta 10, Croatia is the Company
                        registered under no. MBS: 080800684, OIB: 35587488489 is the organizer of Job Fair Contest
                        2022and for such purposes would qualify as a Controller under GDPR 2016/679/EU.</p>

                    <p>This Privacy Notice only applies to the processing of Personal Data of Data subject for which
                        Superology is responsible and qualifies as Controller under GDPR 2016/679/EU, as further
                        described in this Privacy Statement.</p>

                    <h2>2. What personal information we collect?</h2>

                    <p>As used in this Privacy Notice, personal data means any information that can be used to
                        individually identify you, directly or indirectly, alone or along with other information, or
                        contact you online or elsewhere.</p>

                    <p>The categories and volume of personal data that we collect vary depending on the activities in
                        relation to which the data is collected and include, but are not limited to:</p>

                    <ul>
                        <li>contact information, such as your name, username, email address and phone numbers;</li>
                        <li>JMBAG</li>
                        <li>information about the University you are engaged with</li>
                        <li>your solution for prize contest</li>
                    </ul>

                    <p>We also collect personal data that we are not receiving directly from you, such as: </p>

                    <ul>
                        <li>information about how you use our website, which we obtain through tracking technologies
                            such as cookies), IP address registration, log files. This information includes the IP
                            address, the registration details in case you did register, as well as information about the
                            pages you have viewed, for how long and your itinerary on our site.
                        </li>
                    </ul>

                    <h3>3. How we collect your personal information?</h3>

                    <p>We solely collect your personal data with your informed knowledge, i.e. when you voluntarily
                        submit it to us or when it is generated during your relationship with us.</p>

                    <p>Collection of data occurs in various ways by forms filled in by you, or at your request by our
                        employees or other dedicated personnel (such as hostesses), fiscal or electronical receipts. For
                        example, personal data collection happens:</p>

                    <ul>
                        <li>When you are registering with our webpage;</li>
                        <li>When you participate to events we organize;</li>
                        <li>When you agree to participate to our marketing campaigns;</li>
                        <li>When you subscribe for other services, such as newsletters;</li>
                        <li>when you contact us for various purposes (feedback, questions, or comments));</li>
                        <li>Through different tracking technologies such as cookies, IP address recording, log files,
                            Google Analytics
                        </li>
                    </ul>

                    <p>Once collected, we shall at all time ensure that any processing of your personal data is carried
                        out in compliance with applicable laws, with this Privacy Notice and for the purpose for which
                        you submitted your data to us. </p>

                    <h2>4. What we use your personal information for?</h2>

                    <p>By law, we must have a lawful reason for processing your personal information. The data we
                        collect about you will only be used for the purpose for which you submitted it to us, as listed
                        and clarified below in text. Such purposes are made clear to you at the point of collection or
                        in this Privacy Notice. Thus, we process your personal data for the following purposes:</p>

                    <ul>
                        <li><strong>processing is necessary for the performance of a contract to which the data subject
                            is party or in order to take steps at the request of the data subject prior to entering into
                            a contract</strong> (e.g. in order to enable you to participate in our prize contest you are
                            part of and to receive prize as a result of such contest );
                        </li>
                        <li><strong>based on your prior consent for marketing communication purposes</strong> (e.g. to
                            register to an event such as contests, , promotional activities, to subscribe to our
                            newsletter service, , to take part in opinion surveys or to use your image in our marketing
                            campaigns). You have the right to refuse or unsubscribe to such communications;
                        </li>
                        <li><strong>in our legitimate interests which we may have</strong>, except when such interests
                            override by your interests or fundamental rights and freedom (e.g., improving our products
                            and services, to provide direct marketing as specified by the data protection law, to
                            strengthen, expand or make our business activity more efficiently, to protect, secure or
                            prevent loss/ damage to our rights, to prevent you from damaging in any way our performance,
                            including committing illicit acts/fraud);
                        </li>
                    </ul>

                    <p>We may also use your personal data for the following purposes:</p>

                    <ul>
                        <li>When you communicate with us for any questions, complaints, requests or to provide guidance,
                            clarification, assistance and support.
                        </li>
                        <li>When we send you administrative communication (e.g. email confirmations when you sign up
                            for, or unsubscribe from, a specific registration or activity and/or send you certain
                            service-related announcements, such as updates to our Privacy Notice or other policies)
                        </li>
                        <li>When we place certain cookies on your computer, called necessary cookies, which are
                            necessary to display our website to you.
                        </li>
                    </ul>

                    <h2>5. What happens if you do not provide or allow us to use your personal data? </h2>

                    <p>You can decide at any time not to provide us with the requested personal data. However, if you
                        refuse to share your personal data with us when requested, we may not be able to fulfill our
                        contractual obligations if the collection and processing of such data is a legal obligation for
                        us You may also not be eligible to participate in certain activities or events if you do not
                        provide us with the minimum required information (for example, if you refuse to share your
                        contact information, you may not participate in a promotional activity because we could not
                        contact you to give you the winnings).</p>

                    <p>Your refusal to share your personal information with us in order to benefit from certain services
                        may also limit the services and special offers we may offer you. For example, if you do not give
                        us your consent to receive the newsletter, you will not be able to receive any of our
                        newsletters. We will not process your personal data if you just want to visit our site and find
                        out more details about our services and products.</p>

                    <h2>6. Who do we share your personal data with?</h2>

                    <p>We will never share your personal data with any third party who intends to use it for commercial
                        purposes or any other purposes except those listed under Point 4. of this document, unless we
                        have expressly informed you and you have given us explicit permission to do so. We may share
                        your personal data with third parties acting as processors, to the following extent and for the
                        following purposes:</p>

                    <h2>7. How long we keep your personal information for?</h2>

                    <p>Your personal data will only be stored for a necessary and strict period of time in accordance
                        with the purpose for which it is collected and applicable legal requirements. For your
                        information, we will retain your personal data:</p>

                    <ul>
                        <li>the existence of our legitimateinterests except when such interests override by your
                            interests or fundamental rights and freedom,
                        </li>
                        <li>your explicit consent to the processing of personal data, for the duration of your consent
                            and not longer that 1 year from the moment you provided us with your consent,
                        </li>
                        <li>until you request the application of your "right to erasure" or "right to portability" of
                            data in accordance with the "Your rights" section, unless there is another legal basis for
                            the continuation of the retention of personal data.
                        </li>
                    </ul>

                    <p>Upon expiration of the above-mentioned deadlines, your personal data will be deleted or
                        anonymised. If, at any time, you wish us to discontinue the use of your personal data and stop
                        providing you with our services, please contact us in the manner described in <strong>“How to
                            access, modify, delete or object to the use of your personal data”</strong> section.</p>

                    <p>If your requests to delete your personal data violate our legal or regulatory obligations, or the
                        data related to the request for deletion are necessary to exercise or defense our legal claims,
                        we may not be able to act upon your request and we will notify you of this decision.</p>

                    <h2>8. How we keep your personal data secure?</h2>

                    <p>Superology has implemented all necessary technical and organizational measures, whether physical,
                        electronic or procedural, to protect the confidentiality and security of the personal data you
                        share with us. These measures have been implemented to protect data against unauthorized access,
                        destruction, loss, alteration, access, disclosure or use. Our security controls are designed to
                        maintain an adequate level of confidentiality, integrity and data availability.</p>

                    <p>9. Your rights</p>

                    <p>At any given time, you will have the following rights regarding your personal data processed by
                        us. Please note, other than your right to object to us using your information for direct
                        marketing (and profiling for the purposes of direct marketing), your rights are not absolute in
                        case of other applicable laws.</p>

                    <p>(A) <strong>The right to information</strong>: you have the right to be provided with information
                        on the identity of the personal data controller, on the reasons for processing you personal data
                        and other relevant information necessary to ensure the correct and transparent processing of
                        your personal data.</p>

                    <p>(B) <strong>The right of access</strong>: you have the right to make a request for details of
                        your personal information and a copy of that personal information. The access to the processed
                        personal data will be conducted on our registered address Kuzminečka cesta 10, Zagreb. In
                        emergency situations where the operation of the system is compromised, the Processor may be
                        entitled to remote access, via an encrypted connection and with the security protocols of
                        appropriate security previously exchanged.</p>

                    <p>(C) <strong>The right of rectification</strong>: you have the right to have inaccurate
                        information about you corrected or removed without undue delay.</p>

                    <p>(D) <strong>The right of erasure ("right to be forgotten") in the following
                        circumstances</strong></p>

                    <ul>
                        <li>if your personal data is no longer necessary for the purpose for which it was originally
                            collected,
                        </li>
                        <li>if you have withdrawn your consent to the processing of your personal data and there is no
                            other legal basis for the continuation of processing,
                        </li>
                        <li>if you oppose processing for which there are no overriding legitimate interests of the
                            Contoller,
                        </li>
                        <li>if the processing of personal data is illegal,</li>
                        <li>if your personal data must be deleted in order to comply with our legal obligations</li>
                    </ul>

                    <p>(E) <strong>The right to restriction of processing</strong>: you have the right to ask us to use
                        your personal information for restricted purposes only.</p>

                    <p>(F) <strong>The right to data portability</strong>: you have the right to ask us to transfer the
                        personal information you have given us to you or to someone else in a format that can be read by
                        computer.</p>

                    <p>(G) <strong>The right to object</strong>: you have the right to object to us processing
                        (including profiling) your personal information in cases where our processing is based on a task
                        carried out in the public interest or where we have let you know it is necessary to process your
                        information for our or a third party’s legitimate interests. You can object to us using your
                        information for direct marketing and profiling purposes in relation to direct marketing.</p>

                    <p>(H) <strong>The right in relation to automated decisions</strong>: you have the right not to have
                        a decision which produces legal effects which concern you or which has a significant effect on
                        you based only on automated processing, unless this is necessary for entering into a contract
                        with you, it is authorised by law or you have given your permission for this.</p>

                    <p>(I) The data subject has the opportunity to file a complaint with the supervisory authority for
                        the protection of personal data at any time:</p>

                    <p>Personal Data Protection Agency<br />
                        Selska cesta 136, 10000 ZAGREB<br />
                        <a href="mailto:azop@azop.hr">azop@azop.hr</a>
                    </p>

                    <p>We will respond to your request within 1 month of receiving it. If we receive a large number of
                        requests, or particularly complex requests, the deadline can be extended by a maximum of another
                        two months. In certain circumstances as prescribed by data protection law (i.e. excessive
                        requests), we might refuse to act upon your request.</p>

                    <h2>10. How you can access, modify, delete or object the use of your personal data</h2>

                    <p>To apply for the purpose of exercising the above rights, please contact us at:
                        data.privacy@superbet.hr</p>

                    <p>In the event that your personal data is processed on the basis of your consent, you may withdraw
                        your consent at any time by sending an email to data.privacy@superbet.com specifying your
                        request, and which withdrawal will be without affecting the lawfulness of processing on the
                        basis of consent given prior to its withdrawal.</p>

                    <p>You may withdraw your prior consent to the processing of personal data at any time for the
                        purpose of sending our promotional messages.</p>

                    <h2>11. Treatment of children's personal data</h2>

                    <p>Organised contest is not intended for children (that is persons under 18 years). Superology does
                        not knowingly collect personal data from minors. You must be over 18 years old to benefit from
                        the services and products of and engage in communications with Superology.</p>

                    <p>By registering to our events and contacting us, you confirm that you are at least 18 years old
                        and are fully able to enter into, comply with and be legally bound by any of our terms and
                        conditions made available to you and this Privacy Statement. If we are notified or learn that a
                        minor person has submitted personal data to us by any means of communication, we will
                        immediately delete his/her personal data.</p>

                    <h2>12. How to contact us</h2>

                    <p>If you have any questions, comments, complaints or suggestions relating to this notice, or any
                        other concerns about the way in which we process information about you, please contact us by
                        emailing on <a href="mailto:data.privacy@superbet.hr">data.privacy@superbet.hr</a></p>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: 'JobFair2022PrivacyNotice',

    props: {},

    data: function () {
        return {}
    },

    created() {

    },

    components: {},

    computed: {},

    methods: {}
}


</script>
