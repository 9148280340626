<template>
  <section class="next-super-team">
    <div class="container">
      <div class="next-team-details">
        <div class="next-team-headline gs-animate fade enter">
          <div class="next-team-cta"
               @mouseover="nextTeamHovered = true"
               @mouseleave="nextTeamHovered = false">
            <router-link :to="nextTeam.url" class="arrow-link">
              <ApplyArrow :hoverColor="hoverColor"/>
              next SUPER team
            </router-link>
          </div>
          <router-link :to="nextTeam.url">
            <h2 class="highlight-green">{{ nextTeam.name }}</h2>
          </router-link>
        </div>
        <div class="next-team-jersey">
          <img ref="jersey" :src="nextTeamJersey" :alt="`${nextTeam.name} jersey`">
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ApplyArrow from "../careers/ApplyArrow"

export default {
  name: "NextSuperTeam",
  props: {
    nextTeam: {
      type: Object,
      required: true
    }
  },

  data: function () {
    return {
      nextTeamHovered: false
    }
  },

  components: {
    ApplyArrow
  },

  computed: {
    nextTeamJersey() {
      const nextTeamName = this.nextTeam.name !== 'People & Culture'
          ? this.nextTeam.name.toLowerCase().replaceAll(' ', '-')
          : 'pnc'
      return require(`../../assets/teams/jersey-team-${nextTeamName}.svg`)
    },
    hoverColor() {
      const colors = {
        hover: '#D1CB19',
        default: '#7D899E'
      }

      return this.nextTeamHovered ? colors.hover : colors.default
    }
  },
}
</script>
