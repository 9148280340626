<template>
    <div class="team role animated fadeIn">
        <div class="hero-header standard careers">
            <div class="container">
                <CompanySubmenu />
                <TeamHeader>
                    <template v-slot:teamTitle>
                        <h1><span class="highlight-green">Android</span><br />team</h1>
                    </template>

                    <template v-slot:subHeader>
                        The people who give<br /> another meaning to<br /> the word team
                    </template>

                    <template v-slot:jersey>
                        <img ref="jersey" src="../../assets/teams/jersey-team-android.svg" alt="Android jersey">
                    </template>
                </TeamHeader>
            </div>
        </div>

        <section class="about-team stripe-mask dark">
            <div class="container">
                <p class="team-description gs-animate fade enter">
                    Our main focus is to make our apps a thrilling experience for users so we keep improving our
                    codebase and
                    knowledge using trending technologies every day and conducting our own mini conferences on a monthly
                    basis. On
                    the job, we're keen on making things pixel perfect and as functional as it gets. We can always count
                    on each
                    other's help and advice. After work, we go for drinks and we also gather up on weekends. We're a
                    team that
                    doesn't just have the best people for the job, we have the best people in general.
                </p>
                <div class="team-details">
                    <div class="team-size gs-animate fade enter">
                        <p class="caption">Team size</p>
                        <p class="size highlight-green">13</p>
                    </div>
                    <div class="tech-stack gs-animate fade enter">
                        <p class="caption">Tech stack</p>
                        <div class="icons">
                            <img src="../../assets/teams/rxJava.svg" alt="RxJava" title="RxJava">
                            <img src="../../assets/teams/android-studio.svg" alt="Android Studio"
                                 title="Android Studio">
                            <img src="../../assets/teams/firebase.svg" alt="Firebase" title="Firebase">
                            <img src="../../assets/teams/kotlin.svg" alt="Kotlin" title="Kotlin">
                            <img src="../../assets/teams/gradle.svg" alt="Gradle" title="Gradle">
                            <img src="../../assets/teams/jetpack-compose.svg" alt="Jetpack Compose"
                                 title="Jetpack Compose">
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <CompanyCollage />

        <JoinTeamCTA v-if="team.jobActive" :team="team" />

        <NextSuperTeam :nextTeam="nextTeam" />
    </div>
</template>

<script>
import gsapReveal from '@/mixins/GsapReveal'

import TeamHeader from '@/components/teams/TeamHeader'
import CompanyCollage from '@/components/teams/CompanyCollage'
import NextSuperTeam from '@/components/teams/NextSuperTeam'
import JoinTeamCTA from '@/components/teams/JoinTeamCTA'
import CompanySubmenu from '@/components/company/CompanySubmenu.vue'
import metadata from '@/util/companyMetadata'

export default {
    name: 'AndroidTeam',

    mixins: [gsapReveal],

    components: {
        TeamHeader,
        CompanyCollage,
        NextSuperTeam,
        JoinTeamCTA,
        CompanySubmenu
    },

    metaInfo: {
        meta: metadata.androidTeam,
        title: 'Android team at Superology',
        link: [
            {rel: 'canonical', href: 'https://superology.com/company/android-team'}
        ]
    },

    computed: {
        team() {
            return this.$store.getters.getTeams['android']
        },
        nextTeam() {
            return this.team.nextTeam
        },
    },

    methods: {}
}
</script>
