<template>
  <swiper-slide>
    <div class="bullets-content-wrap">
      <p class="description">{{ description }}</p>

      <div class="showcase-image">
        <img :src="activeBulletImage" :srcset="activeBulletImageSizes" alt="Superology app">
      </div>
    </div>
  </swiper-slide>
</template>

<script>
import {SwiperSlide} from 'vue-awesome-swiper'
import TimelineBulletImage from "../../mixins/TimelineBulletImage.js"

export default {
  name: "ShowcaseSwiper",

  props: {
    description: {
      type: String,
      required: true
    },
    activeBulletYear: {
      type: String,
      required: true
    }
  },

  mixins: [TimelineBulletImage],

  components: {SwiperSlide},

  computed: {
    companyProductShowcase() {
      return this.$store.getters.getCompanyProductShowcase
    },
  },

}
</script>
