import Home from '@/views/Home.vue'
import Legal from '@/views/Legal.vue'
import PrivacyStatement from '@/views/PrivacyStatement.vue'
import CookiePolicy from '@/views/CookiePolicy.vue'
import JobFairContest2022 from '@/views/JobFairContest2022.vue'
import JobFair2022PrivacyNotice from '@/views/JobFair2022PrivacyNotice.vue'
import OutdatedBrowser from '@/views/OutdatedBrowser.vue'

export default [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/legal',
        name: 'Legal',
        component: Legal
    },
    {
        path: '/privacy-statement',
        name: 'Privacy statement',
        component: PrivacyStatement
    },
    {
        path: '/cookie-policy',
        name: 'Cookie policy',
        component: CookiePolicy
    },
    {
        path: '/job-fair-contest-2022',
        name: 'Job fair contest 2022',
        component: JobFairContest2022
    },
    {
        path: '/job-fair-contest-2022-privacy-notice',
        name: 'Job fair contest 2022 privacy notice',
        component: JobFair2022PrivacyNotice
    },
    {
        path: '/can-you-not',
        name: 'Outdated browser',
        component: OutdatedBrowser
    },
]
