<template>
  <div class="company-submenu" :class="{sticky: isSticky}">
    <div class="links">
      <SubmenuLink :link="link" v-for="link in links" :key="link"/>
    </div>
  </div>
</template>

<script>
import SubmenuLink from "./SubmenuLink"

export default {
  name: "CompanySubmenu",

  components: {SubmenuLink},

  computed: {
    links() {
      return this.$store.getters.getCompanySubMenuLinks
    },

    isSticky(){
      return this.$store.getters.getMenuIsSticky
    }
  }
}
</script>
