<template>
  <section class="what-makes-us-super" id="culture">
    <div class="container">
      <div class="hero-bullets no-border">
        <div class="bullet headline">
          <h2 class="gs-animate fade enter">
            What makes us <br/><span class="highlight-green">SUPER</span>
          </h2>
          <div class="we-are-super-ico">
            <img src="../../assets/what-makes-us-super.svg" alt="What makes us super">
          </div>
        </div>
      </div>

      <div class="super-bullets">
        <div class="super-bullet-grid">
          <div class="super-bullet sub-header gs-animate fade enter">
            <div class="description ltd">
              From our 2012 beginnings, we stayed true to a number of beliefs and practices that now shape
              <span class="highlight-green">Superology culture</span>.
            </div>
          </div>

          <div class="super-bullet gs-animate fade enter">
            <div class="super-bullet-icon">
              <img src="../../assets/company/enthusiasm.svg" alt="Enthusiasm">
            </div>
            <div class="super-bullet-content">
              <h3>Enthusiasm</h3>
              <div class="description">
                Our product is our brain baby.
                We are genuinely excited about it
                and love to see it prosper.
              </div>
            </div>
          </div>

          <div class="super-bullet gs-animate fade enter">
            <div class="super-bullet-icon">
              <img src="../../assets/company/proactivity.svg" alt="Enthusiasm">
            </div>
            <div class="super-bullet-content">
              <h3>Proactivity</h3>
              <div class="description">
                See something that can be fixed?
                Go for it. Have an idea? Share it.
              </div>
            </div>
          </div>

          <div class="super-bullet gs-animate fade enter">
            <div class="super-bullet-icon">
              <img src="../../assets/company/responsibility.svg" alt="Enthusiasm">
            </div>
            <div class="super-bullet-content">
              <h3>Responsibility</h3>
              <div class="description">
                Own both your triumphs and
                mistakes. We respect that.
              </div>
            </div>
          </div>

          <div class="super-bullet gs-animate fade enter">
            <div class="super-bullet-icon">
              <img src="../../assets/company/open-communication.svg" alt="Enthusiasm">
            </div>
            <div class="super-bullet-content">
              <h3>Open communication</h3>
              <div class="description">
                Speak out. But also hear your
                teammates out.
              </div>
            </div>
          </div>

          <div class="super-bullet gs-animate fade enter">
            <div class="super-bullet-icon">
              <img src="../../assets/company/autonomy.svg" alt="Enthusiasm">
            </div>
            <div class="super-bullet-content">
              <h3>Autonomy</h3>
              <div class="description">
                We provide context but the goal
                and how to get there is up to you.
              </div>
            </div>
          </div>

          <div class="super-bullet gs-animate fade enter">
            <div class="super-bullet-icon">
              <img src="../../assets/company/integrity.svg" alt="Enthusiasm">
            </div>
            <div class="super-bullet-content">
              <h3>Integrity</h3>
              <div class="description">
                We count on you to do the right
                thing even when nobody’s
                watching.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "WhatMakesUsSuper"
}
</script>
