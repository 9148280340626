<template>
  <div id="outdated" v-if="isIeRoute">
    <router-view/>
  </div>
  <div id="superology" v-else>
    <HeaderMenu/>
    <router-view/>
    <Footer/>

    <transition name="fade">
      <CookiePopup v-if="cookiePopupVisible"/>
    </transition>

  </div>
</template>

<script>
import HeaderMenu from '@/components/layout/header/Header'
import Footer from '@/components/layout/Footer'
import CookiePopup from '@/components/layout/CookiePopup'

export default {
  name: 'Superology',
  components: {HeaderMenu, Footer, CookiePopup},


  computed: {
    isIeRoute(){
      return this.$route.name === 'Outdated browser'
    },

    cookiePopupVisible(){
      return this.$store.getters.getCookiePopupVisible
    },

    cookiePreferences(){
      return this.$store.getters.getCookiePreferences
    }
  }
}
</script>

